import React,{useEffect , useState} from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'

const HeaderBannerComponent = ({ banner, bannerMobile, title, sx }) => {
  const theme = useTheme()
  const [mnHeight , setmnHeight] = useState(150)
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  useEffect(() => {
    isDesktop && setmnHeight(320)
  },[isDesktop])
  return (
    <Box
      mb={2.5}
      sx={{
        minHeight: mnHeight,
        background: `url(${
          isDesktop ? banner : bannerMobile
        }) no-repeat center`,
        backgroundSize: 'cover',
        ...sx,
      }}
    >
      <Container
        sx={{
          pt: 2,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            '&:first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {title}
        </Typography>
      </Container>
    </Box>
  )
}

HeaderBannerComponent.propTypes = {
  banner: PropTypes.string,
  bannerMobile: PropTypes.string,
  title: PropTypes.string,
  sx: PropTypes.object,
}

export default HeaderBannerComponent
