import { Box } from '@mui/system'
import React from 'react'
import { Skeleton } from '@mui/material'
const SkeletonNewsUI = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Skeleton variant="rectangular" width={'100%'} height={118} />
        <Skeleton width="100%" />
        <Skeleton width="95%" />
        <Skeleton width="95%" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: 2,
          mb: 2,
        }}
      >
        <Skeleton variant="rectangular" width={'100%'} height={118} />
        <Skeleton width="100%" />
        <Skeleton width="95%" />
        <Skeleton width="95%" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: 2,
          mb: 2,
        }}
      >
        <Skeleton variant="rectangular" width={'100%'} height={118} />
        <Skeleton width="100%" />
        <Skeleton width="95%" />
        <Skeleton width="95%" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: 2,
          mb: 2,
        }}
      >
        <Skeleton variant="rectangular" width={'100%'} height={118} />
        <Skeleton width="100%" />
        <Skeleton width="95%" />
        <Skeleton width="95%" />
      </Box>
    </Box>
  )
}

export default SkeletonNewsUI
