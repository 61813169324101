import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const name = 'services'

const ENDPOINTS = {
  SERVICES: '/api/v1/services_all/',
  SERVICE: slug => `/api/v1/services/${slug}/`,
  SUB_SERVICE: slug => `/api/v1/subservices/${slug}/`,
  SHORT_SERVICES: '/api/v1/services_short/',
  POPULAR_SERVICES: '/api/v1/popular/',
}

export const getServices = createAsyncThunk(
  `${name}/getServices`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.SHORT_SERVICES)
    return response.data
  }
)

export const getService = createAsyncThunk(
  `${name}/getService`,
  async (slug, { extra: api }) => {
    const response = await Promise.any([
      api.get(ENDPOINTS.SERVICE(slug)),
      api.get(ENDPOINTS.SUB_SERVICE(slug)),
    ])
    return response.data
  }
)

export const getShortServices = createAsyncThunk(
  `${name}/getShortServices`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.SHORT_SERVICES)
    return response.data
  }
)

export const getPopularServices = createAsyncThunk(
  `${name}/getPopularServices`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.POPULAR_SERVICES)
    return response.data
  }
)

const initialState = {
  servicesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  services: [],
  servicesError: null,

  serviceStatus: REQUEST_STATUSES.NOT_REQUESTED,
  service: null,
  serviceError: null,

  shortServicesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  shortServices: [],
  shortServicesError: null,

  popularServicesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  popularServices: [],
  popularServicesError: null,
}

const servicesSlice = createSlice({
  name,
  initialState,
  reducers: { reset: () => initialState },
  extraReducers(builder) {
    addQueryCases(builder, getServices, {
      status: 'servicesStatus',
      data: 'services',
      error: 'servicesError',
    })
    addQueryCases(
      builder,
      getService,
      {
        status: 'serviceStatus',
        data: 'service',
        error: 'serviceError',
      },
      { concat: false }
    )
    addQueryCases(builder, getShortServices, {
      status: 'shortServicesStatus',
      data: 'shortServices',
      error: 'shortServicesError',
    })
    addQueryCases(builder, getPopularServices, {
      status: 'popularServicesStatus',
      data: 'popularServices',
      error: 'popularServicesError',
    })
  },
})

export const selectServicesState = state => state[name]
export const selectServices = state => state[name].services
export const selectCurrentService = state => state[name].service
export const selectCurrentServiceStatus = state => state[name].serviceStatus
export const selectAllShortServices = state => state[name].shortServices

export default servicesSlice
