import React from 'react'
import { Box, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const ServiseDesktopCard = props => {
  const { t } = useTranslation()
  const { services } = props
  return (
    <Box
      sx={{
        height: '375px',
        width: '350px',
        border: '1px solid #CED7DE',
        borderRadius: '14px',
      }}
      {...props}
    >
      <Box sx={{ marginLeft: '20px', marginRight: '20px', height: '200px' }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            mb: 1,
            mt: 1.5,
          }}
        >
          {services?.service?.title}
        </Typography>
        <Typography variant="body1" fontWeight="400">
          {services?.service?.short_description}
        </Typography>
        <Box mt={2}>
          <Link
            to={`/services/${services?.service?.slug}`}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              sx={{
                width: '130px',
                height: '24px',
                color: '#014782',
                display: 'flex',
                justifyContent: 'space-around',
              }}
              variant="button"
            >
              {t('readMore')} <NavigateNextIcon />
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          width: '120px',
          height: '105px',
          float: 'right',
          marginRight: '31px',
          mt: 1,
          '& img': {
            width: '100%',
            height: '105px',
          },
        }}
      >
        <img src={services?.service?.icon} alt="img" />
      </Box>
    </Box>
  )
}

export default ServiseDesktopCard
