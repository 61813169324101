import React from 'react'
import { keyframes } from '@emotion/react'

import { IconButton } from '@mui/material'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'

const CallButton = () => {
  const pulseAnimation = keyframes`
    70% {
      box-shadow: 0 0 0 35px rgba(1, 71, 130, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(1, 71, 130, 0);
    }
  `
  return (
    <IconButton
      sx={{
        position: 'fixed',
        bottom: 32,
        right: 16,
        width: 56,
        height: 56,
        bgcolor: 'primary.blue',
        color: 'primary.main',
        zIndex: 1111111,
        boxShadow: '0 0 0 0 rgba(1, 71, 130, 0.5)',
        animation: `${pulseAnimation} 1.5s linear infinite`,
        animationDelay: '1.5s',

        '&:hover': {
          bgcolor: 'primary.blue',
        },
      }}
      LinkComponent="a"
      href="tel:1366"
    >
      <PhoneEnabledIcon />
    </IconButton>
  )
}

export default CallButton
