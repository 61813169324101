import React from 'react'
import { PATHS } from 'common/constants'
import { Box, Typography } from '@mui/material'
// import { BASE_URL } from 'common/constants'
import { Link } from 'react-router-dom'

const ServiseDesktopCard = props => {
  const { search } = props

  const image = search?.images ? search?.images : search?.photo
  const path = search?.path

  const slug = search?.slug
  const subSlug = search?.sub_slug

  return search?.category === 'Цены' ? (
    <Box sx={{ '& a': { textDecoration: 'none' } }}>
      <Link to={path} state={{ search: { search } }}>
        <Box
          sx={{
            minHeight: '232px',
            maxHeight: '244px',
            width: '520px',
            borderRadius: '8px',
            border: '1px solid  #CED7DE',
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid  #CED7DE',
              minHeight: '84px',
              display: 'flex',
              flexDirection: 'column',
              ml: 2,
              mr: 2,
            }}
          >
            <Typography
              sx={{
                color: '#2C3944',
                fontSize: '22px',
                fontWeight: 500,
                mt: 1.5,
                mb: 1,
              }}
            >
              {search?.price_catalog?.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                textAlign: 'ceneter',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Typography sx={{ width: '200px' }}>{search?.title}</Typography>
              <Typography>{search?.price} сом</Typography>
            </Box>
          </Box>
          <Box sx={{ ml: 2, mr: 2 }}>
            <Typography
              sx={{
                color: '#2C3944',
                fontSize: '22px',
                fontWeight: 600,
                mt: 1.5,
                mb: 1,
              }}
            >
              {search?.title}
            </Typography>
            <Typography sx={{ mb: 1 }}>{search?.category}</Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  ) : search?.category === 'Врачи' ? (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '8px',
        border: '1px solid  #CED7DE',
        height: '110px',
        alignItems: 'center',
        width: '540px',
      }}
    >
      <Box
        sx={{
          '& img': {
            borderRadius: '8px',
            width: '72px',
            height: '72px',
            marginLeft: '19px',
          },
        }}
      >
        <img alt="cardImg" src={image}></img>
      </Box>
      <Box sx={{ marginLeft: '20px', marginRight: '20px' }}>
        <Typography
          sx={{
            color: '#2C3944',
            fontSize: '22px',
            fontWeight: 600,
            mb: 1,
            mt: 1.5,
          }}
        >
          {search?.title
            ? search?.title?.length >= 30
              ? `${search?.title.slice(0, 25)}...`
              : search?.title
            : search?.name}
        </Typography>
        <Typography sx={{ mb: 2 }}>{search?.category}</Typography>
      </Box>
    </Box>
  ) : (
    <Box sx={{ '& a': { textDecoration: 'none' } }}>
      <Link
        to={
          subSlug
            ? `${PATHS.SUBSERVICES}${subSlug}`
            : path
            ? path
            : slug
            ? `${PATHS.SERVICES}${slug}`
            : '/'
        }
        state={{ search: { search } }}
      >
        <Box
          sx={{
            maxHeight: '244px',
            width: '520px',
            border: '1px solid #CED7DE',
            borderRadius: '14px',
          }}
          {...props}
        >
          <Box sx={{ marginLeft: '20px', marginRight: '20px' }}>
            <Typography
              sx={{
                color: '#2C3944',
                fontSize: '22px',
                fontWeight: 600,
                mb: 1,
                mt: 1.5,
              }}
            >
              {search?.title
                ? search?.title?.length >= 30
                  ? `${search?.title.slice(0, 25)}...`
                  : search?.title
                : search?.name}
            </Typography>
            <Typography sx={{ mb: 2 }}>{search?.category}</Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  )
}

export default ServiseDesktopCard
