import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

export const name = 'aboutUs'

const ENDPOINTS = {
  ABOUTUS: '/api/v1/about-us/',
}

export const getAboutUs = createAsyncThunk(
  `${name}/getAboutUs`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.ABOUTUS)
    return response.data
  }
)

const initialState = {
  aboutUsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  aboutUs: [],
  aboutUsError: null,
}

const aboutUsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getAboutUs, {
      status: 'aboutUsStatus',
      data: 'aboutUs',
      error: 'aboutUsError',
    })
  },
})

export const selectAboutUs = state => state[name].aboutUs

export default aboutUsSlice
