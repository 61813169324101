import servicesSlice, { name as servicesSliceName } from './servicesSlice'
import newsSlice, { name as newsSliceName } from './newsSlice'
import infoSlice, { name as infoSliceName } from './infoSlice'
import doctorsSlice, { name as doctorsSliceName } from './doctorsSlice'
import vacanciesSlice, { name as vacanciesSliceName } from './vacanciesSlice'
import pricesSlice, { name as pricesSliceName } from './pricesSlice'
import contactHrSlice, {name as contactHrSliceName} from './contactHrSlice'
import searchSlice, { name as searchSliceName } from './searchSlice'
import aboutUsSlice , {name as aboutUsSliceName } from './aboutUsSlice'
const reducerObj = {
  [servicesSliceName]: servicesSlice.reducer,
  [newsSliceName]: newsSlice.reducer,
  [infoSliceName]: infoSlice.reducer,
  [doctorsSliceName]: doctorsSlice.reducer,
  [vacanciesSliceName]: vacanciesSlice.reducer,
  [pricesSliceName]: pricesSlice.reducer,
  [contactHrSliceName]: contactHrSlice.reducer,
  [searchSliceName]: searchSlice.reducer,
  [aboutUsSliceName]: aboutUsSlice.reducer
}

export default reducerObj
