import React from 'react'
import { Box } from '@mui/material'
import ServiseCategoryCardDesktop from './ServiceCateogoryCardDesktop'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import { Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link } from 'react-router-dom'
// import { PATHS } from 'common/constants'
import { useTranslation } from 'react-i18next'
const ServiseCategoryCard = props => {
  const { service } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  if (isDesktop) {
    return <ServiseCategoryCardDesktop services={props} />
  }
  return (
    <Box
      sx={{
        width: '95%',
        border: '1px solid #CED7DE',
        borderRadius: '14px',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          width: '48px',
          height: '40px',
          marginLeft: '18px',
          mt: 2.625,
          '& img': {
            width: '100%',
            height: '40px',
          },
        }}
      >
        <img src={service?.icon} alt="img" />
      </Box>
      <Box sx={{ marginLeft: '20px', marginRight: '20px', height: '200px' }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            mb: 1,
            mt: 1.5,
          }}
        >
          {service?.title}
        </Typography>
        <Typography variant="body1" fontWeight="400">
          {service?.short_description}
        </Typography>
        <Box mt={1}>
          <Link
            to={`/services/${service?.slug}`}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              sx={{
                width: '130px',
                height: '24px',
                color: '#014782',
                display: 'flex',
                justifyContent: 'space-around',
              }}
              variant="button"
            >
              {t('readMore')} <NavigateNextIcon />
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default ServiseCategoryCard
