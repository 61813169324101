import { Card, CardContent, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectContacts } from 'slices/infoSlice'

const ContactsComponent = ({ sx }) => {
  const { t } = useTranslation()
  const contacts = useSelector(selectContacts)
  return (
    <Card sx={sx}>
      <CardContent>
        <Typography
          variant="h3"
          fontSize={20}
          lineHeight="28px"
          sx={{
            mb: 2.5,
          }}
        >
          {t('signUpForAppointment')}
        </Typography>
        {contacts?.map(contact => (
          <Box mb={2.75} key={contact?.id}>
            <Typography
              variant="subtitle3"
              fontWeight={400}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              gutterBottom
            >
              <img
                src={contact?.icon}
                alt="contact"
                height="24"
                width="24"
                style={{
                  marginRight: 8,
                }}
              />
              {contact?.title}
            </Typography>
            <Typography whiteSpace="pre-wrap">
              {contact?.description}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  )
}

export default ContactsComponent
