import { REQUEST_STATUSES } from 'common/constants'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const name = 'news'

const ENDPOINTS = {
  NEWS: '/api/v1/news/',
}

export const getNews = createAsyncThunk(
  `${name}/getNews`,
  async (num, { extra: api }) => {
    if (num) {
      const response = await api.get(`${ENDPOINTS.NEWS}${num}`)
      return response.data
    } else {
      const response = await api.get(`${ENDPOINTS.NEWS}`)
      return response.data
    }
  }
)

export const getNewsSearchResult = createAsyncThunk(
  `${name}/getNews`,
  async (id, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.NEWS}?id=${id}`)
    return response.data
  }
)

const initialState = {
  status: REQUEST_STATUSES.NOT_REQUESTED,
  news: [],
  next: {},
  error: null,
}

const newsSlice = createSlice({
  name,
  initialState,
  reducers: { reset: () => initialState },
  extraReducers(builder) {
    builder
      .addCase(getNews.pending, state => {
        state.status = REQUEST_STATUSES.REQUESTED
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.status = REQUEST_STATUSES.SUCCEEDED
        const newState = state.news.concat(action.payload?.results)
        state.news = newState
        state.next = action.payload?.next
      })
      .addCase(getNews.rejected, (state, err) => {
        state.status = REQUEST_STATUSES.FAILED
        console.log(err, 'error')
      })
  },
})

export const selectAllNews = state => state.news.news

export const { reset } = newsSlice.actions

export default newsSlice
