import { keyframes, useTheme } from '@emotion/react'
import { Box } from '@mui/system'
import React from 'react'

const LoaderComponent = ({ height = 28, width = 28, sx }) => {
  const loadingAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `

  const theme = useTheme()

  return (
    <Box
      display="inline-block"
      width={width}
      height={height}
      sx={{
        border: '1.5px solid #C2E3FE',
        borderBottomColor: theme.palette.primary.blue,
        animation: `${loadingAnimation} 1s linear infinite`,
        borderRadius: '50%',
        ...sx,
      }}
    ></Box>
  )
}

export default LoaderComponent
