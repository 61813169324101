import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const name = 'search'

const ENPOINTS = {
  DOCTORS_SEARCH: '/search/doctor-search/',
  NEWS_SEARCH: '/search/news-search/',
  PRICES_SEARCH: '/search/price-search/',
  SERVISES_SEARCH: '/search/services-search/',
  SPEC_SEARCH: '/search/spec-search/',
  SUB_SERVISES_SEARCH: '/search/sub-services-search/',
}

export const getDoctorsSearch = createAsyncThunk(
  `${name}/getDoctorsSearch`,
  async (search, { extra: api }) => {
    const response = await api.get(ENPOINTS.DOCTORS_SEARCH, {
      params: {
        search,
      },
    })
    return response.data
  }
)

export const getNewsSearch = createAsyncThunk(
  `${name}/getNewsSearch`,

  async (search, { extra: api }) => {
    const response = await api.get(ENPOINTS.NEWS_SEARCH, {
      params: {
        search,
      },
    })
    return response.data
  }
)

export const getPricesSearch = createAsyncThunk(
  `${name}/getPricesSearch`,
  async (search, { extra: api }) => {
    const response = await api.get(ENPOINTS.PRICES_SEARCH, {
      params: {
        search,
      },
    })
    return response.data
  }
)

export const getServicesSearch = createAsyncThunk(
  `${name}/getServicesSearch`,
  async (search, { extra: api }) => {
    const response = await api.get(ENPOINTS.SERVISES_SEARCH, {
      params: {
        search,
      },
    })
    return response.data
  }
)

export const getSpecsSearch = createAsyncThunk(
  `${name}/getSpecsSearch`,
  async (search, { extra: api }) => {
    const response = await api.get(ENPOINTS.SPEC_SEARCH, {
      params: {
        search,
      },
    })
    return response.data
  }
)

export const getSubServicesSearch = createAsyncThunk(
  `${name}/getSubServicesSearch`,
  async (search, { extra: api }) => {
    const response = await api.get(ENPOINTS.SUB_SERVISES_SEARCH, {
      params: {
        search,
      },
    })
    return response.data
  }
)

const initialState = {
  searchStatus: REQUEST_STATUSES.NOT_REQUESTED,
  search: [],
  searchError: null,
}

const searchSlice = createSlice({
  name,
  initialState,
  reducers: { reset: () => initialState },
  extraReducers(builder) {
    addQueryCases(builder, getDoctorsSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
    })
    addQueryCases(builder, getNewsSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
    })
    addQueryCases(builder, getPricesSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
    })
    addQueryCases(builder, getServicesSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
    })
    addQueryCases(builder, getSpecsSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
    })
    addQueryCases(builder, getSubServicesSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
    })
  },
})

export const selectSearch = state => state[name].search

export const { reset } = searchSlice.actions

export default searchSlice
