import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Slide, Typography } from '@mui/material'
import { useRef } from 'react'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
const StatisticCard = ({
  value,
  desc,
  showPlus,
  animate,
  animationDuration,
}) => {
  const intervalRef = useRef()
  const [currentValue, setCurrentValue] = useState(0)
  const [stopAnimation, setStopAnimation] = useState(false)
  const [border, setBorder] = useState('none')
  const [paddingCount , setPaddingCount]  = useState(0)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    isDesktop && setBorder('1px solid #CED7DE')
    setPaddingCount('24px')
  }, [isDesktop])
  useEffect(() => {
    if (currentValue >= value) {
      setStopAnimation(true)
      clearInterval(intervalRef.current)
    }
  }, [currentValue, value])

  useEffect(() => {
    if (animate === false || stopAnimation) return () => null

    const interval =
      animationDuration / value >= 10 ? animationDuration / value : 10

    const increment =
      animationDuration / value > 10 ? 1 : (value / animationDuration) * 10

    const intervalId = setInterval(() => {
      setCurrentValue(prevVal => {
        if (prevVal + increment >= value) return value
        return prevVal + increment
      })
    }, interval)

    intervalRef.current = intervalId

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [animate, animationDuration, value, stopAnimation])
  return (
    <Box
      sx={{
        borderRadius: '14px',
        border: border,
        padding: paddingCount
      }}
    >
      <Typography
        component="h5"
        sx={{
          color: 'primary.blue',
          fontSize: '36px',
          lineHeight: '48px',
          fontWeight: 700,
          mb: 0.5,
        }}
      >
        {animate ? Math.floor(currentValue) : value}

        {showPlus &&
          (animate ? (
            <Slide
              direction="down"
              in={showPlus && value === currentValue}
              mountOnEnter
              unmountOnExit
            >
              <Box display="inline-block">+</Box>
            </Slide>
          ) : (
            <>+</>
          ))}
      </Typography>
      <Typography>{desc}</Typography>
    </Box>
  )
}

export default StatisticCard
