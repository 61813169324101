import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

export const name = 'contactHr'

const ENDPOINDTS = {
  CONTACTHR: 'api/v1/contact-hr/',
}

export const getContactHr = createAsyncThunk(
  `${name}/getContactHr`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINDTS.CONTACTHR)
    return response.data
  }
)

const initialState = {
  contactHrStatus: REQUEST_STATUSES.NOT_REQUESTED,
  contactHr: [],
  contactHrError: null,
}

const contactHrSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getContactHr, {
      status: 'contactHrStatus',
      data: 'contactHr',
      error: 'contactHrError',
    })
  },
})

export const selectContactHr = state => state[name].contactHr

export default contactHrSlice
