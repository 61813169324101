import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

export const name = 'prices'

const ENPOINTS = {
  PRICES: 'api/v1/price/',
}

export const getPrices = createAsyncThunk(
  `${name}/getPrices`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.PRICES)
    return response.data
  }
)

const initialState = {
  pricesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  prices: [],
  pricesError: null,
}
const pricesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getPrices, {
      status: 'pricesStatus',
      data: 'prices',
      error: 'pricesError',
    })
  },
})

export const selectPrices = state => state[name].prices

export default pricesSlice
