import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

export const name = 'doctors'

const ENPOINTS = {
  DOCTORS: '/api/v1/doctors/',
  SPECIALIZATIONS: '/api/v1/specializations/',
}

export const getDoctors = createAsyncThunk(
  `${name}/getDoctors`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.DOCTORS)
    return response.data
  }
)

export const getSpecializations = createAsyncThunk(
  `${name}/getSpecializations`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.SPECIALIZATIONS)
    return response.data
  }
)

const initialState = {
  doctorsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  doctors: [],
  doctorsError: null,

  specializationsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  specializations: [],
  specializationsError: null,
}

const doctorsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getDoctors, {
      status: 'doctorsStatus',
      data: 'doctors',
      error: 'doctorsError',
    })
    addQueryCases(builder, getSpecializations, {
      status: 'specializationsStatus',
      data: 'specializations',
      error: 'specializationsError',
    })
  },
})

export const selectDoctors = state => state[name].doctors

export default doctorsSlice
