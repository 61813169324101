import React, { useEffect, useState, } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getSpecializations } from 'slices/doctorsSlice'
import {
  Box,
  Container,
  Typography,
  Menu,
  MenuItem,
  Button,
  Skeleton,
} from '@mui/material'
import LinksUI from 'components/UI/LinksUI'
import DoctorCard from '../../components/UI/Cards/Doctor'
import arrowDown from '../../assets/ui-icons/arrowDown.svg'
import arrowUp from '../../assets/ui-icons/arrowUp.svg'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'

const DoctorsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()

  const location = useLocation()
  useEffect(() => { }, [location])

  useEffect(() => {
    dispatch(getSpecializations())
  }, [dispatch])
  const { doctors } = useSelector(state => state.doctors)
  const { specializations } = useSelector(state => state.doctors)

  const [content, setContent] = useState(false)

  const searchTitle = location?.state?.search?.search.title
    ? location?.state?.search?.search.title
    : t('doctors') === 'Дарыгерлер' ? 'Баары' : 'Все'

  const [title, setTitle] = useState(searchTitle)

  useEffect(() => {
    setTimeout(() => setContent(true), 700)
  }, [content])

  const [arrow, setArrow] = useState(arrowDown)

  const [anchorEl, setAnchorEl] = useState(false)

  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setArrow(arrowUp)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setArrow(arrowDown)
  }

  const reload = () => {
    setContent(false)
  }


  // React.useEffect(() => {
  //   return t('doctors') === 'Дарыгерлер' ? setAllDoctorsTr('Баары') : 'Все'
  // },[t])

  const loader = Array.from(Array(6)).map((_, index) => (
    <li key={index}>
      <Box
        sx={{
          width: '255px',
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          mt: 2,
        }}
      >
        <Skeleton
          variant="rectangular"
          width={233}
          height={180}
          sx={{ borderRadius: '14px', margin: 'auto', mb: 0, mt: 0 }}
        />
        <Skeleton
          width={200}
          height={48}
          sx={{ margin: 'auto', mt: 2, mb: 0 }}
        />
        <Skeleton width={180} sx={{ margin: 'auto', mt: 2, mb: 0 }} />
        <Skeleton width={200} sx={{ margin: 'auto', mt: 2 }} />
      </Box>
    </li>
  ))

  return (
    <Container>
      <Box
        sx={{
          mb: 5,
          '& a': { textDecoration: 'none' },
          '& .MuiBackdrop-root': { width: '100% !important' },
        }}
      >
        <Box>
          <LinksUI path={t('doctors')} />
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              color: '#000',
              mb: 3,
              [theme.breakpoints.up('lg')]: { fontSize: '36px', mb: 5, mt: 2 },
            }}
          >
            {t('doctors')}
          </Typography>

          <Typography sx={{ mb: 1.5, fontSize: '14px' }}>
            {t('chooseSpecialization')}
          </Typography>
          <Box
            onClick={handleClick}
            sx={{
              border: '1px solid',
              width: '100%',
              [theme.breakpoints.up('lg')]: {
                width: '35%',
              },
              borderRadius: '8px',
              height: '44px',
              display: 'flex',
              justifyContent: 'space-between',
              '& img': { width: '11px', mr: 1 },
            }}
          >
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                fontWeight: 500,
                '& img': { ml: 1 },
                fontSize: '16px',
              }}
            >
              {title}
            </Button>
            <img alt="arrow" src={arrow}></img>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& div': {
                width: '100%',
                [theme.breakpoints.up('lg')]: {
                  width: '25% !important',
                },
              },
              '& .MuiBackdrop-root': {
                width: '100% !important',
              },
              '& .MuiPaper-root': {
                bottom: '10px',
                height: '60%',
                overflowY: 'overlay',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose()
                reload()
                setTitle(t('doctors') === 'Дарыгерлер' ? 'Баары': 'Все')
              }}
            >
              {t('doctors') === 'Дарыгерлер' ? 'Баары': 'Все'}
            </MenuItem>
            {specializations?.map((specialization, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  handleClose()
                  reload()
                  setTitle(specialization?.title)
                }}
              >
                {specialization?.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& li': {
              listStyleType: 'none',
              [theme.breakpoints.up('xs')]: {
                margin: 'auto',
              },
              [theme.breakpoints.up('lg')]: {
                margin: '0',
                marginRight: '1%',
              },
            },
            '& div': {
              [theme.breakpoints.up('lg')]: {
                marginRight: '1%',
              },
            },
            [theme.breakpoints.up('lg')]: {
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              flexDirection: 'row',
            },
          }}
        >
          {content ? (
            <>
              {location?.state?.search?.search.name ? (
                <Box
                  sx={{
                    [theme.breakpoints.down('xs')]: {
                      margin: 'auto',
                    },
                    mt: 2,
                  }}
                >
                  <DoctorCard doctor={location?.state?.search?.search} />
                </Box>
              ) : (
                doctors?.map((doctor, i) =>
                  title === 'Все' || title === 'Баары' ? (
                    <li key={i}>
                      <DoctorCard doctor={doctor} />
                    </li>
                  ) : (
                    doctor?.specialization?.title === title && (
                      <li key={i}>
                        <DoctorCard doctor={doctor} />
                      </li>
                    )
                  )
                )
              )}
            </>
          ) : (
            <>{loader}</>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default DoctorsPage
