import React, { useState } from 'react'
import { Box, Container } from '@mui/system'
import LinksUI from 'components/UI/LinksUI'
import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector,} from 'react-redux'
import message from '../../../assets/message.svg'
import Loader from 'components/UI/Loader/LoaderUI'
import { REQUEST_STATUSES } from 'common/constants'
import { useTranslation } from 'react-i18next'
const VacancyDesktop = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const { vacancies , vacanciesStatus } = useSelector(state => state.vacancy)
  const { contactHr } = useSelector(state => state.contactHr)
if(vacanciesStatus === REQUEST_STATUSES.NOT_REQUESTED){
  return (
    <Box
      position="fixed"
      display="flex"
      justifyContent="center"
      alignItems="center"
      top="0"
      bottom="0"
      width="100vw"
      overflow="hidden"
      bgcolor="primary.main"
    >
      <Loader
        sx={{
          // position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        }}
      />
    </Box>
  )
}
  return (
    <Box>
      <Container>
        <LinksUI path={t('vacancies')} />
        <Typography variant="h2">{t('vacancies')}</Typography>
        <Box sx={{ mt: 4.25 }}>
          {vacancies &&
            vacancies?.map((vacancy, i) => (
              <Accordion
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`)}
                sx={{
                  background: 'none',
                  border: 'none !important',
                  boxShadow: 'none !important',
                  mb: 2,
                  position: 'static !important',
                }}
                key={vacancy?.id}
              >
                <AccordionSummary
                  sx={{ padding: 0, boxShadow: 'none !important' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{ fontSize: '24px', color: '#161A1D', fontWeight: 500 }}
                  >
                    {vacancy?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ boxShadow: 'none !important' }}>
                  <Typography>{vacancy?.description}</Typography>

                  <Typography sx={{ fontWeight: 700, mt: 1 }}>
                    {t('candidate')}:
                    {contactHr[0]?.email}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
        <Box sx={{ mt: 2, padding: 1 }}>
          <Typography variant="body1">
              {t('candidate2')}
          </Typography>
        </Box>
        <Box
          sx={{
            border: '1px solid #CED7DE',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '8px',
            height: '100px',
            alignItems: 'center',
            mb: 10,
            mt: 2,
            padding: 2.5,
          }}
        >
          <Box>
            <Typography variant="subtitle3">{t('writeToUs')}!</Typography>
            <Typography sx={{ mt: 1.5 }}>{contactHr[0]?.email}</Typography>
          </Box>
          <Box>
            <img src={message} alt="photo" />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default VacancyDesktop
