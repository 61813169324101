import React from 'react'
import { PATHS } from 'common/constants'
import { Box, Typography } from '@mui/material'
import ServiseDesktopCard from './ServiseDesktopCard'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import { BASE_URL } from 'common/constants'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const ServiseCard = props => {
  const { search } = props

  const image = search?.images ? search?.images : search?.photo

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  // "Специялизации" "Врачи" "Новости" "Услуги" "Подуслуги"
  const path = search?.path

  const slug = search?.slug

  const { t } = useTranslation()
  if (isDesktop) {
    return <ServiseDesktopCard search={search} />
  }
  return search?.category === ('Цены' || 'Баалар') ? (
    <Box sx={{ '& a': { textDecoration: 'none' } }}>
      <Link to={path} state={{ search: { search } }}>
        <Box
          sx={{
            minHeight: '178px',
            borderRadius: '8px',
            border: '1px solid',
            mt: 1.5,
            mb: 1.5,
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid',
              minHeight: '84px',
              display: 'flex',
              flexDirection: 'column',
              ml: 2,
              mr: 2,
            }}
          >
            <Typography
              sx={{
                color: '#2C3944',
                fontSize: '16px',
                fontWeight: 600,
                mt: 1.5,
                mb: 1,
              }}
            >
              {search?.price_catalog?.title}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Typography sx={{ width: '200px' }}>{search?.title}</Typography>
              <Typography>{search?.price} сом</Typography>
            </Box>
          </Box>
          <Box sx={{ ml: 2, mr: 2 }}>
            <Typography
              sx={{
                color: '#2C3944',
                fontSize: '16px',
                fontWeight: 600,
                mt: 1.5,
                mb: 1,
              }}
            >
              {search?.title}
            </Typography>
            <Typography sx={{ mb: 1 }}>{search?.category}</Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  ) : (
    <Box sx={{ '& a': { textDecoration: 'none' } }}>
      <Link
        to={path ? path : slug ? `${PATHS.SERVICES}${slug}` : '/'}
        state={{ search: { search } }}
      >
        <Box
          sx={{
            height: '72px',
            display: 'flex',
            '& img': { width: '72px', height: '72px', mr: 1.5 },
          }}
          {...props}
        >
          {image ? (
            image?.[0]?.image ? (
              <Box sx={{ '& img': { borderRadius: '8px' } }}>
                <img
                  alt="cardImg"
                  src={`${BASE_URL}${image?.[0]?.image}`}
                ></img>
              </Box>
            ) : (
              <Box sx={{ '& img': { borderRadius: '8px' } }}>
                <img alt="cardImg" src={image}></img>
              </Box>
            )
          ) : (
            <></>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            <Typography
              sx={{ color: '#2C3944', fontSize: '16px', fontWeight: 600 }}
            >
              {search?.title
                ? search?.title?.length >= 30
                  ? `${search?.title.slice(0, 25)}...`
                  : search?.title
                : search?.name}
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
              {search?.category}
            </Typography>
            <Typography
              sx={{
                color: '#014782',
                textDecorationLine: 'underline',
                mt: 1.5,
              }}
            >
              {t('see')}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  )
}

export default ServiseCard
