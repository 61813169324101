import React, { useState, useRef } from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import useCollapse from 'react-collapsed'
import { Button } from '@mui/material'
import parse from 'html-react-parser'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import NewsPhotoSlider from './NewsPhotoSlider'
import {useTranslation} from "react-i18next";
const NewsCardDesktop = React.memo(({ news, id }) => {
  const updated_at = new Date(news?.updated_at).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  const [isExpanded, setExpended] = useState(id ? true : false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  const newsRef = useRef()
  const {t} = useTranslation()
  const handleScroll = () => {
    newsRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <Box
      sx={{
        mb: 10,
        pt: 4,
      }}
      ref={newsRef}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '50%',
          }}
        >
          <NewsPhotoSlider images={news?.images} />
        </Box>
        <Box
          sx={{
            width: '50%',
          }}
        >
          <Typography variant="h3">{news?.title}</Typography>
          <Typography variant="caption">{updated_at}</Typography>
          <Box sx={{ mt: 2.25 }}>
            <Box variant="body1" fontWeight="400">
              {!isExpanded ? (
                <Box {...getToggleProps()}>
                  <Box sx={{ pt: '1px' }} variant="body1">
                    {parse(news?.content)}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ mt: 2 }} variant="body1">
                  {news?.short_description}
                </Box>
              )}
            </Box>
            <Button
              sx={{ mt: 3.5, width: '246px', height: '44px !important', display: 'flex !important',  }}
              {...getCollapseProps({
                onClick: () => setExpended(prevExpended => !prevExpended),
              })}
              variant="contained"
            >
              {!isExpanded ? (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                  }}
                  fontWeight="600"
                  fontSize="18px"
                  onClick={() => handleScroll()}
                >
                  {t("hideText")}
                  <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                </Typography>
              ) : (
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                  }}
                  fontWeight="600"
                  fontSize="18px"
                >
                  {t("readFull")}
                  <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
export default NewsCardDesktop


