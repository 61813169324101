import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Typography } from '@mui/material'

const ClientCard = ({ image, name, sx }) => {
  return (
    <Card sx={sx}>
      <CardContent
        sx={{
          textAlign: 'center',
          '& img': {
            mb: 2,
          },
        }}
      >
        <img src={image} alt="client" />
        <Typography>{name}</Typography>
      </CardContent>
    </Card>
  )
}

ClientCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  sx: PropTypes.object,
}

export default ClientCard
