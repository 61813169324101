import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { PATHS } from 'common/constants'
import { useTheme } from '@emotion/react'
const NewsCard = ({ news, sx }) => {
  const createdAt = new Date(news.created_at).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  if (isDesktop) {
    return (
      <Card
        sx={{
          display: 'block',
          textDecoration: 'none',
          ...sx,
          height: '350px',
          width: '540px',
          // paddingBottom: '30px'
        }}
        component={Link}
        to={`${PATHS.NEWS}?id=${news?.id}`}
      >
        <CardMedia
          image={news.images[0]?.image}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            pb: 1.25,
            pr: 1.5,
            height: '150px',
          }}
        >
          <Typography variant="caption" color="card.main">
            {createdAt}
          </Typography>
        </CardMedia>
        <CardContent>
          <Typography fontWeight={600} color="card.text" component="h6">
            {news?.title}
          </Typography>
          <Typography variant="caption">{news?.short_description.slice(0,250) + '...'}</Typography>
        </CardContent>
      </Card>
    )
  }
  return (
    <Card
      sx={{ display: 'block', textDecoration: 'none', ...sx }}
      component={Link}
      to={`${PATHS.NEWS}?id=${news?.id}`}
    >
      <CardMedia
        image={news.images[0]?.image}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          pb: 1.25,
          pr: 1.5,
          minHeight: 80,
        }}
      >
        <Typography variant="caption" color="card.main">
          {createdAt}
        </Typography>
      </CardMedia>
      <CardContent>
        <Typography fontWeight={600} color="card.text" component="h6">
          {news?.title}
        </Typography>
        <Typography variant="caption">{news?.short_description.slice(0,250) + '...'}</Typography>
      </CardContent>
    </Card>
  )
}

NewsCard.propTypes = {
  news: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
    short_description: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        isMain: PropTypes.bool,
      })
    ),
    created_at: PropTypes.string,
  }),
  sx: PropTypes.object,
}

export default NewsCard
