export const BASE_URL = process.env.REACT_APP_BASE_URL

// PATHS FOR ROUTES
export const PATHS = {
  SEARCH_RESULTS: '/search-results/',
  DOCTORS: '/doctors/',
  PRICES: '/prices',
  NEWS: '/news/',
  ABOUT_US: '/about-us/',
  CONTACTS: '/contacts',
  VACANCY: '/vacancy/',
  SERVICES: '/services/',
  SUBSERVICES: '/subservices/',
}

export const REQUEST_STATUSES = {
  NOT_REQUESTED: 'notRequested',
  REQUESTED: 'requested',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
}
