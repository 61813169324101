import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Container } from '@mui/system'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Pagination } from 'swiper'
import 'swiper/css/pagination'
import LinksUI from 'components/UI/LinksUI'
import { Typography, useMediaQuery } from '@mui/material'
import { PATHS } from 'common/constants'
import DoctorCard from 'components/UI/Cards/Doctor'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { useTheme } from '@emotion/react'
import ScrollToTop from 'components/ScrollToTop/ScrollToTopComponent'
import AboutUsPhotoSlider from 'pages/AboutUs/Desktop/AboutUsPhotoSlider'
import Contacts from 'components/Contacts'
import { selectCurrentServiceStatus } from 'slices/servicesSlice'
import { useSelector } from 'react-redux'
import { REQUEST_STATUSES } from 'common/constants'
import Loader from 'components/UI/Loader/LoaderUI'
import { getService } from 'slices/servicesSlice'
import { useDispatch } from 'react-redux'
const SubservicesPage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { t } = useTranslation()
  const { slug } = useParams()
  useEffect(() => {
    dispatch(getService(slug))
  }, [dispatch, slug])
  const serviceStatus = useSelector(selectCurrentServiceStatus)
  const subservice = useSelector(state => state.services.service)

  if (
    serviceStatus === REQUEST_STATUSES.REQUESTED ||
    serviceStatus === REQUEST_STATUSES.NOT_REQUESTED
  ) {
    return (
      <Box
        position="fixed"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        bottom="0"
        width="100vw"
        overflow="hidden"
        bgcolor="primary.main"
      >
        <Loader
          sx={{
            // position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      </Box>
    )
  }
  if (isDesktop) {
    return (
      <Box sx={{ mb: 10 }}>
        <Container>
          <LinksUI path={subservice?.title} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '45%' }}>
              {parse(`${subservice?.description}`)}
            </Box>
            <Box sx={{ width: '45%' }}>
              <Box
                sx={{
                  mb: 5.626,
                }}
              >
                <AboutUsPhotoSlider images={subservice?.images} />
              </Box>
              <Contacts />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {subservice?.service_content ||
              subservice?.subservice_content?.map((content, idx) => (
                <Box key={content?.id} sx={{ width: '45%' }}>
                  {parse(`${content?.content}`)}
                </Box>
              ))}
          </Box>
          <Container>
            <Box mb={1.5} mt={2}>
              <Typography
                variant="h2"
                sx={{
                  '&.MuiTypography-root': {
                    display: 'inline-block',
                    mb: 0,
                    mr: 1.5,
                  },
                }}
              >
                {t('doctors')}
              </Typography>
              <Typography
                color="primary.blue"
                sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                component={Link}
                to={PATHS.DOCTORS}
              >
                — {t('allDoctors')}
              </Typography>
            </Box>
          </Container>
          <Swiper
            slidesPerView={4}
            spaceBetween={150}
            // centeredSlides={true}
            // navigation={true}

            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {subservice?.doctors?.map(doctor => (
              <SwiperSlide key={doctor.id}>
                <DoctorCard
                  doctor={doctor}
                  sx={{
                    '&:hover img': {
                      transform: 'scale(1.3)',
                    },

                    '& img': {
                      transition: 'transform .5s',
                      height: '180px',
                      width: '223px',
                      borderRadius: '8px',
                      ml: 'auto',
                      mr: 'auto',
                    },
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
        <ScrollToTop />
      </Box>
    )
  }
  return (
    <Box>
      <Container>
        <LinksUI path={subservice?.title} />
        <Box>
          <Typography variant="h3">{subservice?.title}</Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '180px',
            mt: '14px',
            mb: 2,
            borderRadius: '8px',
          }}
        >
          <img
            style={{
              borderRadius: '8px',
              height: '180px',
              width: '100%',
              height: '100%',
            }}
            src={subservice?.images[0]?.image}
            alt=""
          />
        </Box>
        <Box>{parse(`${subservice?.description}`)}</Box>
      </Container>
      <Box
        component="section"
        pt={2.5}
        pb={6}
        mb={6}
        sx={{
          backgroundColor: '#EDF0F3',

          '& .swiper-slide': {
            display: 'flex',
            justifyContent: 'center',
            width: '80%',
          },
          '& .swiper-slide  .MuiBox-root': {
            bgcolor: 'card.main',
          },
        }}
      >
        <Container>
          <Box mb={1.5} mt={2}>
            <Typography
              variant="h2"
              sx={{
                '&.MuiTypography-root': {
                  display: 'inline-block',
                  mb: 0,
                  mr: 1.5,
                },
              }}
            >
              {t('doctors')}
            </Typography>
            <Typography
              color="primary.blue"
              sx={{
                display: 'inline',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              component={Link}
              to={PATHS.DOCTORS}
            >
              — {t('allDoctors')}
            </Typography>
          </Box>
        </Container>
        <Swiper
          slidesPerView={1}
          spaceBetween={8}
          centeredSlides={true}
          // navigation={true}

          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          {subservice?.doctors?.map(doctor => (
            <SwiperSlide key={doctor?.id}>
              <DoctorCard
                doctor={doctor}
                sx={{
                  '&:hover img': {
                    transform: 'scale(1.3)',
                  },

                  '& img': {
                    transition: 'transform .5s',
                    height: '180px',
                    width: '223px',
                    borderRadius: '8px',
                    ml: 'auto',
                    mr: 'auto',
                  },
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default SubservicesPage
