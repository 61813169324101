import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import { useLocation } from 'react-router-dom'

import store from 'store'
import { PATHS } from 'common/constants'
import theme from './theme'
import Header from 'components/Header'
import MainPage from 'pages/Main'
import SearchResultsPage from 'pages/SearchResults'
import DoctorsPage from 'pages/Doctors'
import AboutUsPage from 'pages/AboutUs'
import NewsPage from 'pages/News'
import PricesPage from 'pages/Prices'
import VacancyPage from 'pages/Vacancy'
import ContactsPage from 'pages/Contacts'
import ServicePage from 'pages/Service/ServicePage'
import SubservicesPage from 'pages/Subservices/SubservicesPage'
import AppProvider from 'providers/App/AppProvider'
import Footer from 'components/Footer'
import { useLayoutEffect } from 'react'
import CallButton from 'components/CallButton'
import { Box } from '@mui/system'
import { userStyles } from 'userStyles'

const Wrapper = ({ children }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <Box sx={userStyles}>
            <Header />
            <Wrapper>
              <Routes>
                <Route index element={<MainPage />} />
                <Route
                  path={PATHS.SEARCH_RESULTS}
                  element={<SearchResultsPage />}
                />
                <Route path={PATHS.DOCTORS} element={<DoctorsPage />} />
                <Route path={PATHS.ABOUT_US} element={<AboutUsPage />} />
                <Route path={PATHS.NEWS} element={<NewsPage />} />
                <Route path={PATHS.PRICES} element={<PricesPage />} />
                <Route path={PATHS.VACANCY} element={<VacancyPage />} />
                <Route path={PATHS.CONTACTS} element={<ContactsPage />} />
                <Route
                  path={`${PATHS.SERVICES}:slug`}
                  element={<ServicePage />}
                />
                <Route
                  path={`${PATHS.SUBSERVICES}:slug`}
                  element={<SubservicesPage />}
                />
              </Routes>
            </Wrapper>
            <Footer />

            <CallButton />
          </Box>
          <CssBaseline />
        </ThemeProvider>
      </AppProvider>
    </Provider>
  )
}

export default App
