import { configureStore } from '@reduxjs/toolkit'
import { requester } from 'requester'
import reducerObj from 'slices'

const store = configureStore({
    reducer: reducerObj,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: requester,
            },
        }),
})

export default store
