import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectStatistic } from 'slices/infoSlice'

import StatisticCard from 'components/UI/Cards/Statistic/StatisticCard'

import { Box, Grid, Typography} from '@mui/material'
import { Container } from '@mui/system'
import { useInViewport } from 'react-in-viewport'
const StatisticsComponent = () => {
  const MLS_FOR_INCREASING = 950
  const [animate, setAnimate] = useState(false)

  const rootRef = useRef()

  const {t} = useTranslation()

  const { inViewport } = useInViewport(rootRef)

  useEffect(() => {
    if (inViewport) {
      setAnimate(true)
    }
  }, [inViewport])

  const statistic = useSelector(selectStatistic)
  const statisticObj = statistic?.[0]

  return (
    <Box component="section" mb={6} >
      <Container>
        <Typography variant="h2" sx={{ mb: 1.75 }}>
          {t('ourIndicators')}
        </Typography>
        <Grid spacing={2} justifyContent="flex-start" container ref={rootRef}>
          {statisticObj &&
            Object.keys(statisticObj).map(statistic => {
              const statisticCardProps = {
                animationDuration: MLS_FOR_INCREASING,
                desc: null,
                value: statisticObj[statistic],
                showPlus: false,
              }

              switch (statistic) {
                case 'years_existed':
                  statisticCardProps.desc = t('yearsExisted')
                  break
                case 'inpatients':
                  statisticCardProps.desc = t('inpatients')
                  break
                case 'patients_visited_daily':
                  statisticCardProps.desc =
                    t('patientsVisitedDaily')
                  statisticCardProps.showPlus = true
                  break
                case 'endoscopic_surgeries':
                  statisticCardProps.desc = t('endoscopicSurgeries')
                  statisticCardProps.showPlus = true
                  break
                case 'neurosurgical_operations':
                  statisticCardProps.desc =
                    t('neurosurgicalOperations')
                  break
                case 'clinic_staff':
                  statisticCardProps.desc = t('clinicStaff')
                  break
                default:
                  return null
              }

              if (!statisticCardProps.desc) return null

              return (
                <Grid item xs={6} lg={4} key={statistic}>
                  <StatisticCard  {...statisticCardProps} animate={animate} />
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default StatisticsComponent
