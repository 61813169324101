import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { PATHS } from 'common/constants'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'
const PopularServiceCard = ({ service, sx }) => {
  // this state for the checking device and set height}
  const [mnHeinght, setHeight] = useState()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  useEffect(() => {
    isDesktop ? setHeight(150) : setHeight(200)
  }, [isDesktop])
  return (
    <Card
      sx={{ display: 'block', textDecoration: 'none', ...sx }}
      component={Link}
      to={`${PATHS.SERVICES}${service.subservice.slug}`}
    >
      <CardMedia image={service.image} sx={{ height: `${mnHeinght}px` }} />
      <CardContent>
        <Typography fontWeight={600} color="card.text" component="h6">
          {service?.title}
        </Typography>
        <Typography variant="caption">{service?.description}</Typography>
      </CardContent>
    </Card>
  )
}

PopularServiceCard.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    subservice: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
  sx: PropTypes.object,
}

export default PopularServiceCard
