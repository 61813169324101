import React from 'react'
// import fragments

import { Box, Container } from '@mui/system'
import { Grid, Button, Typography, useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import { Pagination, Navigation } from 'swiper'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Banner from 'components/Banner'
import ServiseCategoryCard from 'components/UI/Cards/ServiceCategory/ServiceCategoryCard'
import 'swiper/css/pagination'
import PopularServiceCard from 'components/UI/Cards/PopularService/PopularServiceCard'
import { useTheme } from '@emotion/react'
import Loader from 'components/UI/Loader/LoaderUI'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link } from 'react-router-dom'
import DoctorCard from 'components/UI/Cards/Doctor/DoctorCard'
import ReviewCard from 'components/UI/Cards/Review/ReviewCard'
import StatisticsComponent from '../StatisticsComponent'
import NewsCard from '../NewsCard'
import { PATHS } from 'common/constants'
import YouTube from 'react-youtube'
// import requests

import {
  selectAllShortServices,
  selectServicesState,
} from 'slices/servicesSlice'
import { selectInfoState } from 'slices/infoSlice'

// import selectors
import { selectDoctors } from 'slices/doctorsSlice'

// import constants
import { REQUEST_STATUSES } from 'common/constants'
import { selectAllNews } from 'slices/newsSlice'
const MainPageDesktop = () => {
  // constants
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  // checking loading statuses variables

  const isLoadingStatus = status =>
    status === REQUEST_STATUSES.REQUESTED ||
    status === REQUEST_STATUSES.NOT_REQUESTED

  // destructization from useSelector
  const { statisticStatus, bannersStatus, banners, reviewsStatus, reviews } =
    useSelector(selectInfoState)
  const { popularServices, popularServicesStatus } =
    useSelector(selectServicesState)
  const allShortServices = useSelector(selectAllShortServices)
  const doctors = useSelector(selectDoctors)
  const doctorsStatus = useSelector(state => state.doctors.doctorsStatus)

  const news = useSelector(selectAllNews)
  const newsStatus = useSelector(state => state.news.status)
  const shortServicesStatus = useSelector(
    state => state.services.shortServicesStatus
  )

  // checking loading
  const isLoading =
    isLoadingStatus(reviewsStatus) ||
    isLoadingStatus(bannersStatus) ||
    isLoadingStatus(popularServicesStatus) ||
    isLoadingStatus(statisticStatus) ||
    isLoadingStatus(shortServicesStatus) ||
    isLoadingStatus(doctorsStatus) ||
    isLoadingStatus(newsStatus)

  const videoUrl = 'https://www.youtube.com/watch?v=Ng49bhHwB0k'
  let videoCode
  if (videoUrl) {
    videoCode = videoUrl.split('v=')[1].split('&')[0]
  }
  const checkElapsedTime = e => {
    const duration = e.target.getDuration()
    const currentTime = e.target.getCurrentTime()
    if (currentTime / duration > 0.95) {
    }
  }
  const opts = {
    width: '500px',
    height: '314px',
  }

  // Layout
  if (isLoading) {
    return (
      <Box
        position="fixed"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        bottom="0"
        width="100vw"
        overflow="hidden"
        bgcolor="primary.main"
      >
        <Loader
          sx={{
            // position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      </Box>
    )
  }
  return (
    <Box
      pb={4.5}
      sx={{
        '& .MuiTypography-h2': {
          mb: 3,
        },

        // swiper styles
        '& .swiper': {
          pb: 7,
        },
        '& .swiper .swiper-slide': {
          height: 'auto',
        },
        '& .swiper-pagination ': {
          bottom: 0,
        },
        '& .swiper-pagination-bullet': {
          opacity: 1,
          bgcolor: 'primary.border',
        },
        '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
          bgcolor: 'primary.blue',
        },
      }}
    >
      {/* Banners */}
      <Box
        component="section"
        height={`calc(100vh - ${theme.spacing(10)})`}
        mb={4}
        sx={{
          '& .swiper': {
            height: '100%',
          },
        }}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          loop={true}
        >
          {banners.map(banner => (
            <SwiperSlide key={banner.id}>
              <Banner banner={banner} sx={{ height: '100%' }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {/* Popular Service */}
      <Box component="section" mb={6}>
        <Container>
          <Typography variant="h2">{t('popular')}</Typography>

          <Swiper
            spaceBetween={50}
            slidesPerView={2}
            navigation={true}
            // centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            style={{
              paddingRight: '50px',
              paddingLeft: '50px',
            }}
            loop={true}
            modules={[Pagination, Navigation, Autoplay]}
          >
            {popularServices.map(popService => (
              <SwiperSlide key={popService.id}>
                <PopularServiceCard
                  service={popService}
                  sx={
                    {
                      // width: '500px',
                      // height: '292px',
                    }
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
      {/* Short service */}
      <Box component="section" mb={6}>
        <Container>
          <Typography variant="h2">{t('ourServices')}</Typography>
          <Grid
            spacing={2}
            container
            sx={{
              mb: 3.75,
            }}
          >
            {allShortServices.slice(0, -1).map(service => (
              <Grid xs={8} lg={4} item key={service.id}>
                <ServiseCategoryCard
                  service={service}
                  sx={{
                    height: '100%',
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {!isDesktop && (
            <Box textAlign="center">
              <Button
                variant="contained"
                sx={{
                  p: 1.25,
                  pl: 2.75,
                  pr: 2.75,
                }}
                href="tel:1366"
              >
                <Typography
                  sx={{
                    '&:first-letter': {
                      textTransform: 'uppercase',
                    },
                  }}
                  variant="button"
                >
                  {t('callAmbulance')}
                </Typography>
              </Button>
            </Box>
          )}
          <Box
            sx={{
              background: '#FFFFFF',
              width: '100%',
              height: '202px',
              border: '1px solid #CED7DE',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {allShortServices.slice(-1).map(ambulance => (
              <Box
                key={ambulance.id}
                sx={{
                  width: '75%',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <img width="120px" height="120px" src={ambulance.icon} alt="photo" />
                <Box sx={{ width: '550px', height: '132px' }}>
                  <Typography variant="subtitle1">
                    {ambulance?.title}
                  </Typography>
                  <Typography variant="body1">
                    {ambulance.short_description}
                  </Typography>
                  <Box mt={2}>
                    <Link
                      to={`${PATHS.SERVICES}${ambulance.slug}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        sx={{
                          width: '130px',
                          height: '24px',
                          color: '#014782',
                          display: 'flex',
                          justifyContent: 'space-around',
                          cursor: 'pointer',
                        }}
                        variant="button"
                      >
                        {t('readMore')} <NavigateNextIcon />
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            ))}
            <Box
              sx={{ width: '25%', display: 'flex', justifyContent: 'center' }}
            >
              {isDesktop && (
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    sx={{
                      p: 1.25,
                      pl: 2.75,
                      pr: 2.75,
                    }}
                    href="tel:1366"
                  >
                    <Typography
                      sx={{
                        '&:first-letter': {
                          textTransform: 'uppercase',
                        },
                      }}
                      variant="button"
                    >
                      {t('callAmbulance')}
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>

      {/* About Us  */}

      <Box component="section" sx={{ mt: 7.5, pb: 10 }}>
        <Container>
          {/* NOTE: this grid is for mobile and desktop  */}
          <Grid container>
            <Grid xs={12} lg={6} item>
              <YouTube
                videoId={videoCode}
                containerClassName="embed embed-youtube"
                onStateChange={e => checkElapsedTime(e)}
                opts={opts}
              />
              {/* <img src={youtube} width="500px" height="314px" alt="" /> */}
              {/* TODO: paste the video */}
            </Grid>
            <Grid sx={{ maxWidth: '420px' }} xs={12} lg={6} item>
              <Typography
                variant="h2"
                sx={{
                  '&.MuiTypography-h2': {
                    mb: 1.75,
                  },
                }}
              >
                {t('aboutClinic')}
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                }}
              >
                {t('AboutUsp1')}
              </Typography>
              <Button
                sx={{
                  mb: 4,
                  p: 0,
                }}
                LinkComponent={Link}
                to={PATHS.ABOUT_US}
                disableTouchRipple
              >
                {t('readMore')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Doctors  */}

      <Box
        component="section"
        pt={2.5}
        pb={6}
        mb={6}
        sx={{
          backgroundColor: '#EDF0F3',

          '& .swiper-slide': {
            display: 'flex',
            justifyContent: 'center',
            width: '80%',
          },
          '& .swiper-slide  .MuiBox-root': {
            bgcolor: 'card.main',
          },
        }}
      >
        <Container>
          <Box mb={1.5}>
            <Typography
              variant="h2"
              sx={{
                '&.MuiTypography-root': {
                  display: 'inline-block',
                  mb: 0,
                  mr: 1.5,
                },
              }}
            >
              {t('doctors')}
            </Typography>
            <Typography
              color="primary.blue"
              sx={{
                display: 'inline',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              component={Link}
              to={PATHS.DOCTORS}
            >
              — {t('allDoctors')}
            </Typography>
          </Box>

          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            // centeredSlides={true}
            navigation={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            style={{
              // '--swiper-navigation-size': '20px'
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            {doctors?.map(doctor => (
              <SwiperSlide style={{ paddingBottom: '10px' }} key={doctor.id}>
                <DoctorCard
                  doctor={doctor}
                  sx={{
                    '& img': {
                      '&:hover': {
                        transform: 'scale(1.1)',
                        width: '88%',
                        height: '45%',
                      },
                      transition: 'transform .7s',
                      height: '180px',
                      width: '223px',
                      borderRadius: '8px',
                      ml: 'auto',
                      mr: 'auto',
                    },
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>

      {/* Reviews  */}
      <Box component="section" mb={6}>
        <Container>
          <Typography variant="h2" sx={{ mb: 1.75 }}>
            {t('reviews')}
          </Typography>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
          >
            {reviews.map((review, idx) => (
              <SwiperSlide
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                key={idx}
              >
                <ReviewCard
                  review={review}
                  sx={{
                    height: '100%',
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>

      {/* Statistic  */}
      <StatisticsComponent />

      {/* News  */}
      <Box component="section" mb={20} mt={10}>
        <Container>
          <Typography variant="h2">{t('news')}</Typography>

          <Swiper
            spaceBetween={80}
            slidesPerView={2}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            loop={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            style={{
              paddingBottom: '30px',
              paddingLeft: '50px',
              paddingRight: '50px',
            }}
          >
            {news?.map((news, idx) => (
              <SwiperSlide
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                key={idx}
              >
                <NewsCard news={news} sx={{ mb: 1.75 }} key={news.id} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
    </Box>
  )
}

export default MainPageDesktop
