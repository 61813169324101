import React, { useEffect } from 'react'
import { Container, Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import Servise from 'components/UI/Cards/Servise'

import { useTheme } from '@emotion/react'
import LinksUI from 'components/UI/LinksUI'
import { useTranslation } from 'react-i18next'
const SearchResults = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Container>
      <LinksUI path={t('searchResult')} />
      <Typography
        sx={{ fontSize: '24px', fontWeight: 700, color: '#000', mb: 3 }}
      >
        {t('searchResult')}
      </Typography>

      <Box
        sx={{
          '& li': {
            listStyleType: 'none',
            mb: 1,
            mt: 1,
            [theme.breakpoints.up('lg')]: {
              marginRight: '1%',
            },
          },
          mb: 8.5,
          [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexWrap: 'wrap',
          },
        }}
      >
        {location?.state?.search?.searchResults?.search?.map((search, i) => (
          <li key={i}>
            <Servise search={search} />
          </li>
        ))}
      </Box>
    </Container>
  )
}

export default SearchResults
