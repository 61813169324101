import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material'
import LinksUI from 'components/UI/LinksUI'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'

import { useLocation } from 'react-router-dom'

const PricesPage = () => {
  const location = useLocation()
  useEffect(() => {}, [location])
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const { prices } = useSelector(state => state.prices)

  const [expanded, setExpanded] = useState('panel100')
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Container sx={{ mb: 10.5 }}>
      <LinksUI path={t('prices')} />

      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 700,
          color: '#000',
          mb: 3,
          [theme.breakpoints.up('lg')]: { fontSize: '36px', mb: 5, mt: 2 },
        }}
      >
        {t('prices')}
      </Typography>

      <Typography sx={{ mb: 1.5, fontSize: '14px' }}>{t('price')}</Typography>

      {/* [theme.breakpoints.up('lg')]: { width: '80%' }, */}
      <Box
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'none',
            [theme.breakpoints.up('lg')]: { width: '80%' },
          },
          '& .MuiAccordion-root:before': {
            backgroundColor: '#fff !important',
          },
          '& .Mui-expanded': {
            minHeight: '24px !important',
            margin: '0 !important',
          },
          '& .MuiAccordionDetails-root': {
            paddingRight: '0',
          },
          '& li': {
            listStyleType: 'none',
          },
          '& ul': {
            padding: '0',
            margin: '0',
          },
        }}
      >
        {prices?.map((price, i) => (
          <li key={i}>
            {location?.state?.search?.search?.price_catalog?.title ===
            price?.title ? (
              <Accordion
                expanded={expanded === `panel100`}
                onChange={handleChange(`panel100`)}
              >
                <AccordionSummary
                  sx={{ padding: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{ fontSize: '18px', color: '#161A1D', fontWeight: 600 }}
                  >
                    {price?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    {price?.prices?.map((cost, i) => (
                      <li key={i}>
                        {cost.title ===
                        location?.state?.search?.search.title ? (
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                mt: 1.5,
                                mb: 1.5,
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                sx={{
                                  width: '200px',
                                  fontWeight: 'bold',
                                  [theme.breakpoints.up('lg')]: {
                                    width: '500px',
                                  },
                                }}
                              >
                                {cost?.title}
                              </Typography>
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {cost?.price} сом
                              </Typography>
                            </Box>
                            <Typography sx={{ fontWeight: 700 }}>
                              {cost?.note}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                mt: 1.5,
                                mb: 1.5,
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                sx={{
                                  width: '200px',
                                  [theme.breakpoints.up('lg')]: {
                                    width: '500px',
                                  },
                                }}
                              >
                                {cost.title}
                              </Typography>
                              <Typography>{cost?.price} сом</Typography>
                            </Box>
                            <Typography sx={{ fontWeight: 700 }}>
                              {cost.note}
                            </Typography>
                          </Box>
                        )}
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            ) : (
              <></>
            )}
          </li>
        ))}
      </Box>

      <Box
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'none',
            [theme.breakpoints.up('lg')]: { width: '80%' },
          },
          '& .MuiAccordion-root:before': {
            backgroundColor: '#fff !important',
          },
          '& .Mui-expanded': {
            minHeight: '24px !important',
            margin: '0 !important',
          },
          '& .MuiAccordionDetails-root': {
            paddingRight: '0',
          },
          '& li': {
            listStyleType: 'none',
          },
          '& ul': {
            padding: '0',
            margin: '0',
          },
        }}
      >
        {prices?.map((price, i) => (
          <li key={i}>
            {location?.state?.search?.search?.price_catalog?.title ===
            price?.title ? (
              <></>
            ) : (
              <Accordion
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`)}
              >
                <AccordionSummary
                  sx={{ padding: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{ fontSize: '18px', color: '#161A1D', fontWeight: 600 }}
                  >
                    {price?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    {price?.prices?.map((cost, i) => (
                      <li key={i}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              mt: 1.5,
                              mb: 1.5,
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              sx={{
                                width: '200px',
                                [theme.breakpoints.up('lg')]: {
                                  width: '500px',
                                },
                              }}
                            >
                              {cost.title}
                            </Typography>
                            <Typography>{cost?.price} сом</Typography>
                          </Box>
                          <Typography sx={{ fontWeight: 700 }}>
                            {cost.note}
                          </Typography>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            )}
          </li>
        ))}
      </Box>
    </Container>
  )
}

export default PricesPage
