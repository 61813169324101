import * as React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { Rating } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import { useTheme } from '@emotion/react'
const ReviewCard = props => {
  const { review, sx } = props
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')) 
  const createdAt = new Date(review.created_at).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  if (isDesktop) {
    return (
      <Card
        sx={{
          width: '600px',
          borderRadius: '10px',
          border: '1px solid #CED7DE',
          maxWidth: 636,
        }}
        style={{
          // height: '300px',
          padding: '25px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          boxShadow: '0px 10px 10px rgba(152, 152, 184, 0.2)',
        }}
      >
        <CardHeader
          avatar={<Avatar src={review?.photo}></Avatar>}
          action={
            <Rating
              name="half-rating-read"
              defaultValue={review?.stars}
              precision={0.5}
              readOnly
            />
          }
          title={review?.user}
          subheader={createdAt?.slice(0, createdAt.length - 3)}
          style={{
            width: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
        <CardContent>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            sx={{
              mb: 1.08,
            }}
          >
            {review?.title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
           {review?.text}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card
      sx={{
        pt: 3,
        pl: 1.5,
        pr: 1.5,

        ...sx,
      }}
    >
      <CardHeader
        sx={{
          p: 0,
          mb: 2,
        }}
        avatar={
          <Avatar
            src={review?.photo}
            sx={{
              width: 48,
              height: 48,
            }}
          />
        }
        title={
          <Box>
            <Typography
              sx={{
                mb: 0.5,
              }}
            >
              {review.user}
            </Typography>
            <Rating
              name="half-rating-read"
              defaultValue={review?.stars}
              precision={0.5}
              readOnly
              sx={{
                '& .MuiSvgIcon-root': {
                  width: 16,
                  height: 17,
                  color: '#FFD80B',
                },
              }}
            />
          </Box>
        }
      />
      <CardContent
        sx={{
          p: 0,
          pb: 0,
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight={600}
          sx={{
            mb: 1.08,
          }}
        >
          {review?.title}
        </Typography>
        <Typography variant="caption" color="text.main">
          {review?.text}
        </Typography>

        <Box sx={{ marginTop: '20px' }}>
          <Typography
            variant="caption"
            sx={{
              color: '#8195A7',
            }}
          >
            {createdAt?.slice(0, createdAt.length - 3)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

ReviewCard.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.string,
    photo: PropTypes.string,
    stars: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
}

export default ReviewCard
