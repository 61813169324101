import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

export const name = 'info'

const ENPOINTS = {
  CONTACTS: 'api/v1/contacts/',
  STATISTIC: '/api/v1/statistic/',
  BANNERS: '/api/v1/banner/',
  REVIEWS: '/api/v1/reviews/',
}

export const getContacts = createAsyncThunk(
  `${name}/getContacts`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.CONTACTS)
    return response.data
  }
)

export const getStatisitic = createAsyncThunk(
  `${name}/getStatistic`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.STATISTIC)
    return response.data
  }
)

export const getBanners = createAsyncThunk(
  `${name}/getBanners`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.BANNERS)
    return response.data
  }
)

export const getReviews = createAsyncThunk(
  `${name}/getReviews`,
  async (_, { extra: api }) => {
    const response = await api.get(ENPOINTS.REVIEWS)
    return response.data
  }
)

const initialState = {
  contactsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  contacts: [],
  contactsError: null,

  statisticStatus: REQUEST_STATUSES.NOT_REQUESTED,
  statistic: [],
  statisticError: null,

  bannersStatus: REQUEST_STATUSES.NOT_REQUESTED,
  banners: [],
  bannersError: null,

  reviewsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  reviews: [],
  reviewsError: null,
}

const infoSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getContacts, {
      status: 'contactsStatus',
      data: 'contacts',
      error: 'contactsError',
    })
    addQueryCases(builder, getStatisitic, {
      status: 'statisticStatus',
      data: 'statistic',
      error: 'statisticError',
    })
    addQueryCases(builder, getBanners, {
      status: 'bannersStatus',
      data: 'banners',
      error: 'bannersError',
    })
    addQueryCases(builder, getReviews, {
      status: 'reviewsStatus',
      data: 'reviews',
      error: 'reviewsError',
    })
  },
})

export const selectInfoState = state => state[name]
export const selectStatistic = state => state[name].statistic
export const selectContacts = state => state[name].contacts

export default infoSlice
