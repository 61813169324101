import React from 'react'
// import fragments

import { Container, Box } from '@mui/system'
import { Typography } from '@mui/material'
import LinksUI from 'components/UI/LinksUI'
import HeaderBanner from 'components/HeaderBanner'
import { useTranslation } from 'react-i18next'
import aboutus from '../../../assets/aboutus.png'
import operation from 'assets/images/AboutUs/operation.svg'
import parse from 'html-react-parser'
import Contacts from 'components/Contacts'
import bed from 'assets/images/AboutUs/bed.svg'
import ambulance from 'assets/images/AboutUs/ambulance.svg'
import monitor from 'assets/images/AboutUs/monitor.svg'
import ClientCard from '../ClientCard'
import kumtor from 'assets/images/AboutUs/kumtor.svg'
import cocaCola from 'assets/images/AboutUs/cocaCola.svg'
import gazprom from 'assets/images/AboutUs/gazprom.svg'
import { useSelector } from 'react-redux'
import AboutUsPhotoSlider from './AboutUsPhotoSlider'
import YouTube from 'react-youtube'

const AboutUsDesktop = () => {
  const { t } = useTranslation()

  const { aboutUs } = useSelector(state => state.aboutUs)

  const videoUrl = 'https://www.youtube.com/watch?v=Ng49bhHwB0k'

  let videoCode;
  if (videoUrl) {
    videoCode = videoUrl.split("v=")[1].split("&")[0];
  }
  const checkElapsedTime = (e) => {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();
    if (currentTime / duration > 0.95) {
    }
  }
  const opts = {
    width: '500px',
    height: '314px',
    borderRadius: '10px'
  };
  return (
    <Box>
      <HeaderBanner banner={aboutus} title={t('aboutClinic')} />
      <Container>
        <LinksUI path={t('aboutUs')} />

        {/* main blokcs  */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/* left blocks
           */}
          <Box
            sx={{
              width: '45%',
            }}
          >
            <Typography gutterBottom>{parse(t('AboutUsp1'))}</Typography>
            <Typography gutterBottom>{parse(t('AboutUsp2'))}</Typography>
            <Typography gutterBottom>{parse(t('AboutUsp3'))}</Typography>
            <Typography gutterBottom>{parse(t('AboutUsp4'))}</Typography>
            <Typography
              sx={{
                mb: 3,
              }}
            >
              {parse(t('AboutUsp5'))}
            </Typography>
            <Box pt={1} pb={1}>
              <YouTube
                videoId={videoCode}
                containerClassName="embed embed-youtube"
                onStateChange={(e) => checkElapsedTime(e)}
                opts={opts}
              />
            </Box>

            <Box mb={3.625}>
              <Typography variant="subtitle2" mt={5} mb={4.8}>
                В данное время клиника предоставляет следующие виды услуг:
              </Typography>
              <Typography
                fontWeight={700}
                color="text.black"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1.5,
                }}
                // gutterBottom
              >
                <img
                  src={ambulance}
                  alt="service"
                  height="32"
                  width="32"
                  style={{
                    marginRight: 12,
                  }}
                />
                {t('AboutUsService1Title')}
              </Typography>
              <Typography whiteSpace="pre-wrap">
                {t('AboutUsService1Desc')}
              </Typography>
            </Box>

            <Box mb={3.625}>
              <Typography
                fontWeight={700}
                color="text.black"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1.5,
                }}
              >
                <img
                  src={bed}
                  alt="service"
                  height="32"
                  width="32"
                  style={{
                    marginRight: 12,
                  }}
                />
                {t('AboutUsService2Title')}
              </Typography>
              <Typography whiteSpace="pre-wrap">
                {t('AboutUsService2Desc')}
              </Typography>
            </Box>

            <Box mb={3.625}>
              <Typography
                fontWeight={700}
                color="text.black"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1.5,
                }}
              >
                <img
                  src={monitor}
                  alt="service"
                  height="32"
                  width="32"
                  style={{
                    marginRight: 12,
                  }}
                />
                {t('AboutUsService3Title')}
              </Typography>
              <Typography whiteSpace="pre-wrap">
                {t('AboutUsService3Desc')}
              </Typography>
            </Box>

            <Box mb={3.625}>
              <Typography
                fontWeight={700}
                color="text.black"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1.5,
                }}
              >
                <img
                  src={operation}
                  alt="service"
                  height="32"
                  width="32"
                  style={{
                    marginRight: 12,
                  }}
                />
                {t('AboutUsService4Title')}
              </Typography>
              <Typography whiteSpace="pre-wrap" gutterBottom>
                {parse(t('AboutUsService4Desc1'))}
              </Typography>

              <Typography whiteSpace="pre-wrap" gutterBottom>
                {parse(t('AboutUsService4Desc2'))}
              </Typography>

              <Typography whiteSpace="pre-wrap" gutterBottom>
                {parse(t('AboutUsService4Desc3'))}
              </Typography>
            </Box>
          </Box>

          {/* right blocks */}

          <Box sx={{ width: '45%' }}>
            <Box
              sx={{
                margin: '0 auto',
                mt: 3,
                mb: 3,
              }}
            >
              <AboutUsPhotoSlider images={aboutUs[0]?.images} />
            </Box>
            <Contacts sx={{ mb: 3 }} />
            <Box mb={6}>
              <Typography
                fontWeight={700}
                color="text.black"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1.5,
                }}
              >
                <img
                  src={operation}
                  alt="service"
                  height="32"
                  width="32"
                  style={{
                    marginRight: 12,
                  }}
                />
                {t('AboutUsService5Title')}
              </Typography>
              <Typography whiteSpace="pre-wrap">
                {parse(t('AboutUsService5Desc1'))}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                {parse(t('AboutUsService5Desc1Ul'))}
                {parse(t('AboutUsService5Des1Ul2'))}
              </Box>

              <Typography whiteSpace="pre-wrap">
                {parse(t('AboutUsService5Desc2'))}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                {parse(t('AboutUsService5Desc2Ul'))}
                {parse(t('AboutUsService5Desc2Ul2'))}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Our clients  */}
        <Typography
          variant="h3"
          fontSize={20}
          lineHeight="28px"
          sx={{
            mb: 2,
            '&:first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {t('ourClients')}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 10 }}>
          <ClientCard
            image={cocaCola}
            name={'Кока-Кола Боттлерс Бишкек'}
            sx={{ mb: 2, width: '350px' }}
          />

          <ClientCard
            image={gazprom}
            name="ГазПромНефтьАзия"
            sx={{ mb: 2, width: '350px' }}
          />

          <ClientCard
            image={kumtor}
            name="Кумтор голд Компани"
            sx={{ mb: 2, width: '350px' }}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default AboutUsDesktop
