import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { Container } from '@mui/system'
import LinksUI from 'components/UI/LinksUI'
import Contacts from 'components/Contacts'
import HeaderBanner from 'components/HeaderBanner'
import ClientCard from './ClientCard'
import aboutus from '../../assets/aboutus.png'
import ambulance from 'assets/images/AboutUs/ambulance.svg'
import bed from 'assets/images/AboutUs/bed.svg'
import monitor from 'assets/images/AboutUs/monitor.svg'
import operation from 'assets/images/AboutUs/operation.svg'
import cocaCola from 'assets/images/AboutUs/cocaCola.svg'
import gazprom from 'assets/images/AboutUs/gazprom.svg'
import kumtor from 'assets/images/AboutUs/kumtor.svg'
import { useTheme } from '@emotion/react'
import AboutUsDesktop from './Desktop/AboutUsDesktop'
import { getAboutUs } from 'slices/aboutUsSlice'
import { REQUEST_STATUSES } from 'common/constants'
import YouTube from 'react-youtube'
const AboutUsPage = () => {
  const dispatch = useDispatch()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { t } = useTranslation()
  const { aboutUs } = useSelector(state => state.aboutUs)
  const videoUrl = 'https://www.youtube.com/watch?v=Ng49bhHwB0k'

  let videoCode;
  if (videoUrl) {
    videoCode = videoUrl.split("v=")[1].split("&")[0];
  }
  const checkElapsedTime = (e) => {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();
    if (currentTime / duration > 0.95) {
    }
  }
  const opts = {
    width: '100%',
    height: '185px'
  };
  const aboutUsStatus = useSelector(state => state.aboutUs.aboutUsStatus)
  useEffect(() => {
    if (aboutUsStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      dispatch(getAboutUs())
    }
  }, [aboutUsStatus, dispatch])

  if (isDesktop) {
    return <AboutUsDesktop />
  }
  return (
    <Box
      sx={{
        // ul style
        '& ul': {
          marginBlockStart: theme.spacing(1),
          marginBlockEnd: theme.spacing(1),
          paddingInlineStart: theme.spacing(3),
        },
        '& li': {
          lineHeight: '24px',
        },
      }}
    >
      <HeaderBanner bannerMobile={aboutus} title={t('aboutClinic')} />
      <Container>
        <LinksUI path={t('aboutUs')} />
        <Typography mb={3} variant="h3">
          Наши специалисты имеют многолетний опыт работы в государственных
          неврологических, нейрохирургических отделениях
        </Typography>
        <Box
          sx={{
            background: `url(${aboutUs[0]?.images[0]?.image}) no-repeat center`,
            height: '200px',
            mb: 3,
            backgroundSize: 'cover',
            borderRadius: '8px',
          }}
        />
        <Typography gutterBottom>{parse(t('AboutUsp1'))}</Typography>
        <Typography gutterBottom>{parse(t('AboutUsp2'))}</Typography>
        <Box pt={1} pb={1}>
          <YouTube
            videoId={videoCode}
            containerClassName="embed embed-youtube"
            onStateChange={(e) => checkElapsedTime(e)}
            opts={opts}
          />
        </Box>
        <Typography gutterBottom>{parse(t('AboutUsp3'))}</Typography>
        <Typography gutterBottom>{parse(t('AboutUsp4'))}</Typography>
        <Typography
          sx={{
            mb: 3,
          }}
        >
          {parse(t('AboutUsp5'))}
        </Typography>

        <Contacts sx={{ mb: 4 }} />

        {/* services  */}

        <Box mb={3.625}>
          <Typography
            fontWeight={700}
            color="text.black"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1.5,
            }}
            // gutterBottom
          >
            <img
              src={ambulance}
              alt="service"
              height="32"
              width="32"
              style={{
                marginRight: 12,
              }}
            />
            {t('AboutUsService1Title')}
          </Typography>
          <Typography whiteSpace="pre-wrap">
            {t('AboutUsService1Desc')}
          </Typography>
        </Box>

        <Box mb={3.625}>
          <Typography
            fontWeight={700}
            color="text.black"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <img
              src={bed}
              alt="service"
              height="32"
              width="32"
              style={{
                marginRight: 12,
              }}
            />
            {t('AboutUsService2Title')}
          </Typography>
          <Typography whiteSpace="pre-wrap">
            {t('AboutUsService2Desc')}
          </Typography>
        </Box>

        <Box mb={3.625}>
          <Typography
            fontWeight={700}
            color="text.black"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <img
              src={monitor}
              alt="service"
              height="32"
              width="32"
              style={{
                marginRight: 12,
              }}
            />
            {t('AboutUsService3Title')}
          </Typography>
          <Typography whiteSpace="pre-wrap">
            {t('AboutUsService3Desc')}
          </Typography>
        </Box>

        <Box mb={3.625}>
          <Typography
            fontWeight={700}
            color="text.black"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <img
              src={operation}
              alt="service"
              height="32"
              width="32"
              style={{
                marginRight: 12,
              }}
            />
            {t('AboutUsService4Title')}
          </Typography>
          <Typography whiteSpace="pre-wrap" gutterBottom>
            {parse(t('AboutUsService4Desc1'))}
          </Typography>

          <Typography whiteSpace="pre-wrap" gutterBottom>
            {parse(t('AboutUsService4Desc2'))}
          </Typography>

          <Typography whiteSpace="pre-wrap" gutterBottom>
            {parse(t('AboutUsService4Desc3'))}
          </Typography>
        </Box>

        {/* more functional  */}
        <Box mb={6}>
          <Typography
            fontWeight={700}
            color="text.black"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <img
              src={operation}
              alt="service"
              height="32"
              width="32"
              style={{
                marginRight: 12,
              }}
            />
            {t('AboutUsService5Title')}
          </Typography>
          <Typography whiteSpace="pre-wrap">
            {parse(t('AboutUsService5Desc1'))}
          </Typography>
          {parse(t('AboutUsService5Desc1Ul'))}
          {parse(t('AboutUsService5Des1Ul2'))}
          <Typography whiteSpace="pre-wrap">
            {parse(t('AboutUsService5Desc2'))}
          </Typography>
          {parse(t('AboutUsService5Desc2Ul'))}
        </Box>

        {/* Our clients */}
        <Typography
          variant="h3"
          fontSize={20}
          lineHeight="28px"
          sx={{
            mb: 2,
            '&:first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {t('ourClients')}
        </Typography>

        <ClientCard
          image={cocaCola}
          name={'Кока-Кола Боттлерс Бишкек'}
          sx={{ mb: 2 }}
        />

        <ClientCard image={gazprom} name="ГазПромНефтьАзия" sx={{ mb: 2 }} />

        <ClientCard image={kumtor} name="Кумтор голд Компани" sx={{ mb: 10 }} />
      </Container>
    </Box>
  )
}

export default AboutUsPage
