import React, { createContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getContacts } from 'slices/infoSlice'
import { getDoctors } from 'slices/doctorsSlice'
import { getPrices } from 'slices/pricesSlice'

export const AppContext = createContext()

const AppProvider = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // global queries
    dispatch(getContacts())
    dispatch(getDoctors())
    dispatch(getPrices())
  }, [dispatch])
  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>
}

export default AppProvider
