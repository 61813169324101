import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
const DoctorUI = ({ doctor, sx, ...props }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        width: '255px',
        height: '400px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // boxSizing: 'border-box',
        p: 2,
        pb: 2,
        border: '2px solid #CED7DE',
        borderRadius: '10px',
        marginTop: '16px',
        backgroundImage: `linear-gradient(0deg, black, transparent), url(${doctor?.photo})`,
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '& img': {
          height: '180px',
          width: '223px',
          borderRadius: '8px',
        },
        ...sx,
      }}
      {...props}
    >
      {/* <img alt="doctor" src={doctor?.photo}></img> */}
      <Typography
        sx={{ color: '#FFFFFF', fontWeight: 'bold' }}
        variant="subtitle3"
      >
        {doctor?.name}
      </Typography>
      <Typography sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>
        <Typography
          sx={{ color: '#FFFFFF' }}
          fontWeight="700"
          variant="caption"
        >
          {t('experience')}: {doctor?.experience_total}
        </Typography>
      </Typography>
      <Typography sx={{ color: '#FFFFFF' }}>{doctor?.description}</Typography>
    </Box>
  )
}

export default DoctorUI
