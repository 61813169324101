import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import { useRef } from 'react'
import useCollapse from 'react-collapsed'
import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import parse from 'html-react-parser'
import { t } from 'i18next'

const NewsCard = React.memo(({ item, id }) => {
  const updated_at = new Date(item?.updated_at).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  const [isExpanded, setExpended] = useState(id ? true : false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  const newsRef = useRef()
  const handleScroll = () => {
    newsRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <Box pt={2} ref={newsRef}>
      <Box mb={3}>
        <Box
          sx={{
            width: '100%',
            borderRadius: '5px',
          }}
        >
          {item?.images.map((img, idx) => (
            <Box key={img.id}>
              {img?.isMain === true && (
                <img
                  src={img?.image}
                  width="100%"
                  style={{ borderRadius: '8px' }}
                  height="170px"
                  alt=""
                />
              )}
            </Box>
          ))}
        </Box>
        <Box>
          <Typography sx={{ mt: 2.25 }} variant="h3" fontWeight="600">
            {item?.title}
          </Typography>
          <Typography variant="caption" fontWeight="400" sx={{ mt: 1 }}>
            {updated_at.slice(0, updated_at.length - 3)}
          </Typography>
        </Box>
        <Box sx={{ mt: 2.25 }}>
          <Box variant="body1" fontWeight="400">
            {!isExpanded ? (
              <Box {...getToggleProps()}>
                <Box sx={{ pt: '1px' }} variant="body1">
                  {parse(item?.content)}
                </Box>
              </Box>
            ) : (
              <Box sx={{ mt: 2 }} variant="body1">
                {item?.short_description} 
              </Box>
            )}
          </Box>
          <Button
            sx={{ mt: 3.5, display: 'flex !important', height: '36px !important'}}
            {...getCollapseProps({
              onClick: () => setExpended(prevExpended => !prevExpended),
            })}
            variant="contained"
          >
            {!isExpanded ? (
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                }}
                variant="h3"
                fontWeight="600"
                onClick={() => handleScroll()}
              >
                {t('hideText')}
                <KeyboardArrowUpIcon sx={{ color: 'white' }} />
              </Typography>
            ) : (
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                }}
                variant="h3"
                fontWeight="600"
              >
                  {t('readFull')}

                <KeyboardArrowDownIcon sx={{ color: 'white' }} />
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
})
export default NewsCard
