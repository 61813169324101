import { useTheme } from '@emotion/react'
import { Typography, useMediaQuery } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import LinksUI from 'components/UI/LinksUI'
import ContactsDesktop from './Desktop/ContactsDesktop'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
const ContactsPage = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { contacts } = useSelector(state => state.info)
  const { t } = useTranslation()
  if (isDesktop) {
    return <ContactsDesktop />
  }
  return (
    <Box sx={{ mb: 10 }}>
      <Container>
        <LinksUI path={t('contacts')} />
        <Box sx={{ mt: 2.5, mb: 3 }}>
          <Typography sx={{ mb: 3 }} variant="h2">
            {t('contacts')}
          </Typography>
          {contacts?.map((item, idx) => (
            <Box
              key={item?.id}
              sx={{
                border: '1px solid #CED7DE',
                borderRadius: '8px',
                background: '#ffffff',
                margin: '0 auto',
                mb: 2,
                display: 'flex',
                justifyContent: 'space-around',
                padding: 2,
              }}
            >
              <Box sx={{ width: '55%', p: 1 }}>
                <Typography variant="h3" mb={1.5}>
                  {item?.title}
                </Typography>

                <Typography variant="body1" whiteSpace="pre-wrap">
                  {item?.description}
                </Typography>
              </Box>
              <Box sx={{ mt: 2.8 }}>
                <img src={item?.icon} alt="photo" />
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default ContactsPage
