import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES } from 'common/constants'
import { addQueryCases } from 'common/helpers'

export const name = 'vacancy'

const ENDPOINTS = {
  VACANCIES: '/api/v1/vacancies/',
}

export const getVacancies = createAsyncThunk(
  `${name}/getVacancies`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.VACANCIES)
    return response.data
  }
)

const initialState = {
  vacanciesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  vacancies: [],
  vacanciesError: null,
}

const vacanciesSlice = createSlice({
  name,
  initialState,
  reducers: { reset: () => initialState },
  extraReducers(builder) {
    addQueryCases(builder, getVacancies, {
      status: 'vacanciesStatus',
      data: 'vacancies',
      error: 'vacanciesError',
    })
  },
})

export const selectVacancies = state => state[name].vacancies
export const { reset } = vacanciesSlice.actions
export default vacanciesSlice
