import React, { useEffect, useState } from 'react'
import HeaderDesktopUI from './Desktop/HeaderDesktopUI'
import NavbarDesktopUI from './Desktop/NavbarDesktopUI'
import call from '../../assets/ui-icons/call.svg'
import close from '../../assets/ui-icons/close.svg'
import search from '../../assets/ui-icons/search.svg'
import menu from '../../assets/ui-icons/menu.svg'
import logoMobile from '../../assets/ui-icons/logoMobile.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { reset } from 'slices/searchSlice'
import { selectAllShortServices, getShortServices } from 'slices/servicesSlice'

import {
  getDoctorsSearch,
  getNewsSearch,
  getPricesSearch,
  getServicesSearch,
  getSpecsSearch,
  getSubServicesSearch,
} from 'slices/searchSlice'

import Servise from 'components/UI/Cards/Servise'

import { PATHS } from 'common/constants'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'

import {
  Box,
  Drawer,
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
  MenuList,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
} from '@mui/material'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'

const HeaderUI = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [searchContent, setSearchContent] = useState('')

  useEffect(() => {
    dispatch(reset())
    dispatch(getDoctorsSearch(searchContent))
    dispatch(getNewsSearch(searchContent))
    dispatch(getPricesSearch(searchContent))
    dispatch(getServicesSearch(searchContent))
    dispatch(getSpecsSearch(searchContent))
    dispatch(getSubServicesSearch(searchContent))
  }, [dispatch, searchContent])
  useEffect(() => {
    dispatch(reset())
    dispatch(getShortServices())
  }, [dispatch])

  const handleDebounce = e => {
    setTimeout(() => setSearchContent(e.target.value), 1000)
  }

  const searchResults = useSelector(state => state.search)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const [menuOpen, setMenuOpen] = useState(false)
  const allShortServices = useSelector(selectAllShortServices)
  const toggleDrawer = () => {
    if (menuOpen === false) {
      setMenuOpen(true)
    } else {
      setMenuOpen(false)
    }
  }
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const changeLang = lang => {
    const currLang = localStorage.getItem('lang')
    if (currLang !== lang) {
      localStorage.setItem('lang', lang)
      window.location.reload()
    }
  }

  if (isDesktop) {
    return (
      <>
        <HeaderDesktopUI changeLang={changeLang} />
        <NavbarDesktopUI />
      </>
    )
  }

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '8px',
        }}
      >
        <Box>
          <Link to="/">
            <img alt="logo" src={logoMobile}></img>
          </Link>
        </Box>

        <Box sx={{ '& a': { textDecoration: 'none' } }}>
          <a href="tel:1366">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  '& img': {
                    width: '18px',
                    marginRight: '5px',
                  },
                }}
              >
                <img alt="call" src={call} />
              </Box>
              <Typography color="primary.blue" variant="call">
                1366
              </Typography>
            </Box>
            <Typography color="text.main">{t('ambulance')}</Typography>
          </a>
        </Box>

        <Box onClick={() => toggleDrawer()}>
          <img alt="menu" src={menu} />
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={menuOpen}
        onClose={() => toggleDrawer()}
        sx={{
          '& .MuiPaper-root': { width: '100%' },
        }}
      >
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '8px',
                height: '46px',
              }}
            >
              <Box sx={{ display: 'flex', margin: 'auto' }}>
                <Typography
                  color={i18n.language === 'ru' ? 'primary.black' : '#8195A7'}
                  onClick={() => changeLang('ru')}
                >
                  Ру
                </Typography>
                /
                <Typography
                  color={i18n.language === 'ky' ? 'primary.black' : '#8195A7'}
                  onClick={() => changeLang('ky')}
                >
                  Кырг
                </Typography>
              </Box>
              <Box onClick={() => toggleDrawer()}>
                <img alt="menu" src={close} />
              </Box>
            </Box>

            <Box
              sx={{
                '& a': {
                  textDecoration: 'none',
                  color: '#2C3944',
                },
              }}
            >
              <TextField
                onClick={handleOpen}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img alt="search" src={search}></img>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '100%',
                  bgcolor: 'primary.main',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important',
                  },
                }}
                placeholder={t('search')}
              ></TextField>
              <MenuList>
                <MenuItem
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: 'none',
                    },
                    '& .MuiAccordion-root:before': {
                      backgroundColor: '#fff !important',
                    },
                    '& .MuiAccordionDetails-root': {
                      padding: 0,
                    },
                    '& ul': {
                      padding: 0,
                    },
                    '& .MuiButtonBase-root': {
                      minHeight: '36px',
                      color: '#2C3944',
                    },
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      sx={{
                        padding: 0,
                        width: '80px',
                        height: '36px',
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {t('services')}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {allShortServices?.map(service => (
                          <Link
                            style={{ textDecoration: 'none' }}
                            key={service?.id}
                            to={`${PATHS.SERVICES}${service?.slug}`}
                          >
                            <MenuItem onClick={() => toggleDrawer()}>
                              {service?.title}
                            </MenuItem>
                          </Link>
                        ))}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>

                <Link to={PATHS.DOCTORS}>
                  <MenuItem onClick={() => toggleDrawer()}>
                    {t('doctors')}
                  </MenuItem>
                </Link>
                <Link to={PATHS.PRICES}>
                  <MenuItem onClick={() => toggleDrawer()}>
                    {t('prices')}
                  </MenuItem>
                </Link>
                <Link to={PATHS.ABOUT_US}>
                  <MenuItem onClick={() => toggleDrawer()}>
                    {t('aboutUs')}
                  </MenuItem>
                </Link>
                <Link to={PATHS.NEWS}>
                  <MenuItem onClick={() => toggleDrawer()}>
                    {t('news')}
                  </MenuItem>
                </Link>
                <Link to={PATHS.VACANCY}>
                  <MenuItem onClick={() => toggleDrawer()}>
                    {t('vacancies')}
                  </MenuItem>
                </Link>
                <Link to={PATHS.CONTACTS}>
                  <MenuItem onClick={() => toggleDrawer()}>
                    {t('contacts')}
                  </MenuItem>
                </Link>
              </MenuList>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                sx={{
                  '& .MuiBox-root': { padding: 0 },
                }}
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '7%',
                    right: '8px',
                    left: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img alt="search" src={search}></img>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      bgcolor: 'primary.main',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none !important',
                      },
                    }}
                    placeholder={t('search')}
                    onChange={e => handleDebounce(e)}
                  ></TextField>
                  {searchContent !== '' ? (
                    <Container
                      sx={{
                        backgroundColor: '#EDF0F3',
                        overflowY: 'scroll',
                        borderRadius: '8px',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          minHeight: '176px',
                          maxHeight: '380px',
                          display: 'flex',

                          flexDirection: 'column',
                          '& li': {
                            listStyleType: 'none',
                            mt: 2,
                            mb: 2,
                          },
                        }}
                      >
                        {searchResults?.search?.map((search, i) => (
                          <li key={i}>
                            <Box onClick={() => toggleDrawer()}>
                              <Servise search={search} />
                            </Box>
                          </li>
                        ))}
                        {searchResults?.search.length > 0 ? (
                          <Box
                            sx={{
                              margin: 'auto',
                              '& a': {
                                textDecoration: 'none',
                              },
                            }}
                          >
                            <Link
                              to={PATHS.SEARCH_RESULTS}
                              state={{ search: { searchResults } }}
                            >
                              <Button
                                onClick={() => {
                                  toggleDrawer()
                                  handleClose()
                                }}
                                sx={{
                                  width: '186px',
                                  height: '44px',
                                  margin: 'auto',
                                  mt: 3.5,
                                  mb: 4,
                                }}
                                variant="contained"
                              >
                                {t('allResult')}
                              </Button>
                            </Link>
                          </Box>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: '#161A1D',
                              margin: 'auto',
                            }}
                          >
                            Результатов не найдено <br></br>Проверьте введённый
                            запрос и попробуйте ещё раз
                          </Typography>
                        )}
                      </Box>
                    </Container>
                  ) : (
                    <></>
                  )}
                </Box>
              </Modal>
            </Box>
          </Box>
        </Container>
      </Drawer>
    </Container>
  )
}

export default HeaderUI
