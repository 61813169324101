import React from 'react'
import parse from 'html-react-parser'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css/pagination'
import { useDispatch, useSelector } from 'react-redux'
import DoctorCard from 'components/UI/Cards/Doctor'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATHS } from 'common/constants'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AboutUsPhotoSlider from 'pages/AboutUs/Desktop/AboutUsPhotoSlider'
import Loader from 'components/UI/Loader/LoaderUI'
import {
  getService,
  selectCurrentService,
  selectCurrentServiceStatus,
} from 'slices/servicesSlice'
import { Container } from '@mui/system'
import Contacts from 'components/Contacts'
import { REQUEST_STATUSES } from 'common/constants'
import ScrollToTop from 'components/ScrollToTop/ScrollToTopComponent'
import LinksUI from 'components/UI/LinksUI'
const ServicePage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { slug } = useParams()
  const services = useSelector(selectCurrentService)
  const serviceStatus = useSelector(selectCurrentServiceStatus)
  useEffect(() => {
    if (services?.slug === slug) return () => null
    dispatch(getService(slug))
  }, [dispatch, services?.slug, slug])
  if (
    serviceStatus === REQUEST_STATUSES.REQUESTED ||
    serviceStatus === REQUEST_STATUSES.NOT_REQUESTED
  ) {
    return (
      <Box
        position="fixed"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        bottom="0"
        width="100vw"
        overflow="hidden"
        bgcolor="primary.main"
      >
        <Loader
          sx={{
            // position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      </Box>
    )
  }
  if (isDesktop) {
    return (
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: '100%',
            height: '320px',
            background: `url(${services?.banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Container>
            <Typography sx={{ pt: 2.875 }} variant="h2">
              {services?.title}
            </Typography>
          </Container>
        </Box>
        <Container>
          <LinksUI path={services?.title} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '45%' }}>
              {parse(`${services?.description}`)}
              <Box
                sx={{
                  // width: '300px',
                  // display: 'flex',
                  // justifyContent: 'start',
                  // flexDirection: 'column',
                  // alignItems: 'flex-start'
                  display: 'inline-block',
                }}
              >
                {' '}
                {services?.subservices &&
                  services?.subservices?.map(serviceItem => (
                    <Link
                      key={serviceItem?.id}
                      style={{ textDecoration: 'none' }}
                      to={`${PATHS.SUBSERVICES}${serviceItem?.slug.toString()}`}
                      state={{ subservice: serviceItem, path: services?.title }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="600"
                        sx={{
                          display: 'flex',
                          // justifyContent: 'space-around',
                          textAlign: 'left',
                          alignItems: 'center',
                          width: '300px',
                          margin: '0 auto',
                          marginBottom: '16px',
                          color: theme.palette.primary.blue,
                          fontSize: '18px !important',
                        }}
                      >
                        {serviceItem?.title}
                        <ArrowForwardIcon />
                      </Typography>
                    </Link>
                  ))}
              </Box>
            </Box>
            <Box sx={{ width: '45%', mt: 2 }}>
              <Box
                sx={{
                  mb: 5.626,
                }}
              >
                <AboutUsPhotoSlider images={services?.images} />
              </Box>
              <Contacts sx={{ position: 'static' }} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {services?.service_content &&
              services?.service_content.map((content, idx) => (
                <Box key={content.id} sx={{ width: '45%' }}>
                  {parse(`${content.content}`)}
                </Box>
              ))}
          </Box>
          <Container mt={2}>
            <Box mb={1.5} mt={2}>
              <Typography
                variant="h2"
                sx={{
                  '&.MuiTypography-root': {
                    display: 'inline-block',
                    mb: 0,
                    mr: 1.5,
                  },
                }}
              >
                {t('doctors')}
              </Typography>
              <Typography
                color="primary.blue"
                sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                component={Link}
                to={PATHS.DOCTORS}
              >
                — {t('allDoctors')}
              </Typography>
            </Box>
          </Container>
          <Swiper
            slidesPerView={3}
            spaceBetween={9}
            // centeredSlides={true}
            loop={services?.doctors?.length >= 3 ? true : false}
            navigation={true}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            style={{
              paddingLeft: '50px',
              paddingBottom: '40px',
            }}
          >
            {services?.doctors?.map(doctor => (
              <SwiperSlide key={doctor?.id}>
                <DoctorCard
                  doctor={doctor}
                  sx={{
                    '&:hover img': {
                      transform: 'scale(1.1)',
                      width: '88%',
                      height: '45%',
                    },

                    '& img': {
                      transition: 'transform .5s',
                      height: '180px',
                      width: '223px',
                      borderRadius: '8px',
                      ml: 'auto',
                      mr: 'auto',
                    },
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
        <ScrollToTop />
      </Box>
    )
  }

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          background: `url(${services?.banner_mobile})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '150px',
          mb: 2,
        }}
      >
        <Container>
          <Typography pt={2} variant="subtitle1" fontWeight="600">
            {services?.title}
          </Typography>
        </Container>
      </Box>
      <Container>
        <LinksUI path={services?.title} />
        <img
          src={services?.images[0]?.image}
          style={{ width: '100%', height: '180px', borderRadius: '8px' }}
          alt=""
        />
        <Box
          style={{
            '& img': {
              maxWidth: '328px',
              height: '180px',
            },
          }}
        >
          {parse(`${services?.description}`)}
        </Box>
        {services?.subservices &&
          services?.subservices?.map(serviceItem => (
            <Link
              key={serviceItem?.id}
              style={{ textDecoration: 'none' }}
              to={`${PATHS.SUBSERVICES}${serviceItem?.slug.toString()}`}
              state={{ subservice: serviceItem, path: services?.title }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="600"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  maxWidth: '306px',
                  margin: '0 auto',
                  marginBottom: '16px',
                  color: theme.palette.primary.blue,
                }}
              >
                {serviceItem?.title}
                <ArrowForwardIcon />
              </Typography>
            </Link>
          ))}

        <Box
          component="section"
          pt={2.5}
          pb={6}
          mb={6}
          sx={{
            backgroundColor: '#EDF0F3',

            '& .swiper-slide': {
              display: 'flex',
              justifyContent: 'center',
              width: '80%',
            },
            '& .swiper-slide  .MuiBox-root': {
              bgcolor: 'card.main',
            },
          }}
        >
          {services?.service_content &&
            services?.service_content.map((content, idx) => (
              <Box key={content.id} sx={{ width: '100%' }}>
                {parse(`${content.content}`)}
              </Box>
            ))}
          <Container>
            <Box mb={1.5} mt={2}>
              <Typography
                variant="h2"
                sx={{
                  '&.MuiTypography-root': {
                    display: 'inline-block',
                    mb: 0,
                    mr: 1.5,
                  },
                }}
              >
                {t('doctors')}
              </Typography>
              <Typography
                color="primary.blue"
                sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                component={Link}
                to={PATHS.DOCTORS}
              >
                — {t('allDoctors')}
              </Typography>
            </Box>
          </Container>
          <Swiper
            slidesPerView={1}
            spaceBetween={8}
            centeredSlides={true}
            // navigation={true}

            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {services?.doctors?.map(doctor => (
              <SwiperSlide key={doctor.id}>
                <DoctorCard
                  doctor={doctor}
                  sx={{
                    '&:hover img': {
                      transform: 'scale(1.3)',
                    },

                    '& img': {
                      transition: 'transform .5s',
                      height: '180px',
                      width: '223px',
                      borderRadius: '8px',
                      ml: 'auto',
                      mr: 'auto',
                    },
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <ScrollToTop />
      </Container>
    </Box>
  )
}

export default ServicePage
