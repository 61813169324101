import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { Typography, Container, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'

const BannerComponent = ({ banner, sx }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <Box
      pt={2}
      sx={{
        background: `url(${
          isDesktop ? banner.image : banner.image_mobile
        }) no-repeat center`,
        backgroundSize: 'cover',
        ...sx,
      }}
    >
      <Container sx={{ mt: 10 }}>
        <Typography variant="h1" sx={{ mb: 0.5, width: '500px' }}>
          {banner?.title}
        </Typography>
        <Typography sx={{ width: '445px', [theme.breakpoints.down('lg')]: { width: '107%' },}}>{banner?.description}</Typography>
      </Container>
    </Box>
  )
}

BannerComponent.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    image_mobile: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  sx: PropTypes.object,
}

export default BannerComponent
