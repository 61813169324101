import React, { useEffect, useState } from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { Box, Container } from '@mui/system'
import LinksUI from 'components/UI/LinksUI'
import message from '../../assets/message.svg'
import theme from 'theme'
import { getVacancies } from 'slices/vacanciesSlice'
import { useSelector, useDispatch } from 'react-redux'
import VacancyDesktop from './Desktop/VacancyDesktop'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getContactHr } from 'slices/contactHrSlice'
import { useTranslation } from 'react-i18next'
import { reset } from 'slices/vacanciesSlice'
const VacancyPage = () => {
  const { t } = useTranslation()
  const { vacancies,  } = useSelector(state => state.vacancy)
  const [expanded, setExpanded] = useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(reset())
    dispatch(getVacancies())
    dispatch(getContactHr())
  }, [dispatch])
  const { contactHr } = useSelector(state => state.contactHr)
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  if (isDesktop) {
    return <VacancyDesktop />
  }
  return (
    <Box>
      <Container>
        <LinksUI path={t('vacancies')} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">{t('vacancies')}</Typography>
        </Box>
        <Box sx={{ mt: 4.25 }}>
          {vacancies &&
            vacancies?.map((vacancy, i) => (
              <Accordion
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`)}
                sx={{
                  background: 'none',
                  border: 'none !important',
                  boxShadow: 'none !important',
                  mb: 2,
                  position: 'static !important',
                }}
                key={vacancy?.id}
              >
                <AccordionSummary
                  sx={{ padding: 0, boxShadow: 'none !important' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{ fontSize: '18px', color: '#161A1D', fontWeight: 500 }}
                  >
                    {vacancy?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ boxShadow: 'none !important' }}>
                  <Typography>{vacancy?.description}</Typography>

                  <Typography sx={{ fontWeight: 700, mt: 1 }}>
                    {t('candidate')}:
                    {contactHr[0]?.email}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
        <Box sx={{ mt: 2, padding: 1 }}>
          <Typography variant="body1">
            {t('candidate2')}
          </Typography>
        </Box>
        <Box
          sx={{
            // width: '95%',
            border: '1px solid #CED7DE',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '8px',
            height: '100px',
            alignItems: 'center',
            mb: 10,
            mt: 2,
            padding: 2.5,
          }}
        >
          <Box>
            <Typography variant="subtitle3">{t('writeToUs')}!</Typography>
            <Typography sx={{ mt: 1.5 }}>{contactHr[0]?.email}</Typography>
          </Box>
          <Box>
            <img src={message} alt="photo" />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default VacancyPage
