import React from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectContacts } from 'slices/infoSlice'
import { Container } from '@mui/system'

import facebook from 'assets/ui-icons/facebook.svg'
import instagram from 'assets/ui-icons/instagram.svg'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
const FooterComponent = () => {
  const contacts = useSelector(selectContacts)
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box bgcolor="#EDF0F3" pb={7}>
      <Box
        sx={{
          [theme.breakpoints.up('lg')]: {
            display: 'none',
          },
        }}
      >
        <iframe
          title="medi"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23405.166002226702!2d74.56784237910156!3d42.838096300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb619bab4512f%3A0x1feea4cdd35ac546!2zTUVE0JgsIEx0ZA!5e0!3m2!1sru!2skg!4v1656649467844!5m2!1sru!2skg"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{
            width: '100%',
            border: 0,
            height: 230,
          }}
        />
      </Box>

      <Container>
        <Box
          pt={8}
          sx={{
            [theme.breakpoints.up('lg')]: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.up('lg')]: {
                width: '57%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              },
            }}
          >
            {contacts.map(contact => (
              <Box
                key={contact?.id}
                mb={1.75}
                pt={2.5}
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    width: '46%',
                  },
                }}
              >
                <Typography
                  color="text.black"
                  component="h4"
                  variant="subtitle3"
                  fontWeight={600}
                  sx={{
                    mb: 0.75,
                  }}
                >
                  {contact?.title}:
                </Typography>
                <Typography variant="caption" whiteSpace="pre-wrap">
                  {contact?.description}
                </Typography>
              </Box>
            ))}
            <Box
              mb={1.75}
              pt={2.5}
              sx={{
                [theme.breakpoints.up('lg')]: {
                  width: '46%',
                },
              }}
            >
              <Typography
                color="text.black"
                component="h4"
                variant="subtitle3"
                fontWeight={600}
                sx={{
                  mb: 0.75,
                }}
              >
                {t('society')}:
              </Typography>
              <Box>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/medi_ltd_clinic/"
                >
                  <img
                    src={instagram}
                    alt="instagram"
                    style={{ marginRight: 16 }}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/MEDI1366"
                >
                  <img src={facebook} alt="facebook" />
                </a>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: '37%',
              height: 300,
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            }}
          >
            <iframe
              title="medi"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23405.166002226702!2d74.56784237910156!3d42.838096300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb619bab4512f%3A0x1feea4cdd35ac546!2zTUVE0JgsIEx0ZA!5e0!3m2!1sru!2skg!4v1656649467844!5m2!1sru!2skg"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              style={{
                width: '100%',
                border: 0,
                height: '100%',
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default FooterComponent
