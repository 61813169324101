import React, { memo, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Box } from '@mui/system'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// import required modules
import { FreeMode, Thumbs } from 'swiper'

const AboutUsPhotoSlider = memo(({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  return (
    <Box
      sx={{
        height: '368px',
        width: '474px',
        borderRadius: '8px',
      }}
    >
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
          width: '474px',
          height: '272px',
          borderRadius: '8px',
        }}
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        className="mySwiper2"
        loop={true}
      >
        {images &&
          images?.map(img => (
            <SwiperSlide key={img?.id}>
              <img
                src={img?.image}
                style={{ borderRadius: '8px', height: '100%', width: '100%' }}
                alt="ss"
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={20}
        slidesPerView={5}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper"
        style={{
          width: '474px',
          height: '80px',
          marginTop: '16px',
          borderRadius: '8px',
        }}
      >
        {images &&
          images?.map(img => (
            <SwiperSlide
              key={img?.id}
              style={{ height: '80px', maxWidth: '80px' }}
            >
              <img
                src={img?.image}
                style={{ height: '80px', width: '80px', borderRadius: '8px' }}
                alt="ff"
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  )
})

export default AboutUsPhotoSlider
