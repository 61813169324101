import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Container,
  MenuList,
  MenuItem,
  TextField,
  Button,
  Typography,
  Menu,
  InputAdornment,
} from '@mui/material'
import arrowDown from '../../../assets/ui-icons/arrowDown.svg'
import search from '../../../assets/ui-icons/search.svg'
import arrowUp from '../../../assets/ui-icons/arrowUp.svg'

import { PATHS } from 'common/constants'
import { Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { selectAllShortServices } from 'slices/servicesSlice'
import { reset } from 'slices/searchSlice'

import {
  getDoctorsSearch,
  getNewsSearch,
  getPricesSearch,
  getServicesSearch,
  getSpecsSearch,
  getSubServicesSearch,
} from 'slices/searchSlice'
import { useTranslation } from 'react-i18next'

const NavbarDesktopUI = () => {
  const [arrow, setArrow] = useState(arrowDown)

  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setArrow(arrowUp)
  }
  const langType = localStorage.getItem('lang')
  const handleClose = () => {
    setAnchorEl(null)
    setArrow(arrowDown)
  }
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [searchContent, setSearchContent] = useState('')

  const [searchDisplay, setSearchDisplay] = useState(false)

  const searchModalWindow = useRef()

  useEffect(() => {
    dispatch(reset())
    dispatch(getDoctorsSearch(searchContent))
    dispatch(getNewsSearch(searchContent))
    dispatch(getPricesSearch(searchContent))
    dispatch(getServicesSearch(searchContent))
    dispatch(getSpecsSearch(searchContent))
    dispatch(getSubServicesSearch(searchContent))
  }, [dispatch, searchContent])

  const handleDebounce = e => {
    setSearchDisplay(true)
    setTimeout(() => setSearchContent(e.target.value), 1000)
  }

  const handleSearchClose = () => {
    setSearchDisplay(false)
  }

  const handleOutsideClick = e => {
    if (!e.path.includes(searchModalWindow.current)) {
      setSearchDisplay(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)
  }, [])

  const allShortServices = useSelector(selectAllShortServices)

  const searchResults = useSelector(state => state.search)
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          '& a': {
            textDecoration: 'none',
            color: '#2C3944',
          },
        }}
      >
        <MenuList sx={{ display: 'flex' }}>
          <MenuItem sx={{ padding: 0 }}>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ fontWeight: 500, '& img': { ml: 1 }, fontSize: '16px' }}
            >
              {t('services')} <img alt="arrow" src={arrow}></img>
            </Button>
          </MenuItem>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& div': { width: '257px' },
              '& .MuiBackdrop-root': { width: '100%' },
            }}
          >
            {allShortServices?.map(service => (
              <Link
                key={service?.id}
                to={`${PATHS.SERVICES}${service.slug}`}
                style={{ textDecoration: 'none' }}
              >
                <MenuItem onClick={handleClose}>{service?.title}</MenuItem>
              </Link>
            ))}
          </Menu>

          <Link to={PATHS.DOCTORS}>
            <MenuItem>{t('doctors')}</MenuItem>
          </Link>
          <Link to={PATHS.PRICES}>
            <MenuItem>{t('prices')}</MenuItem>
          </Link>
          <Link to={PATHS.ABOUT_US}>
            <MenuItem> {t('aboutUs')}</MenuItem>
          </Link>
          <Link to={PATHS.NEWS}>
            <MenuItem>{t('news')}</MenuItem>
          </Link>
          <Link to={PATHS.VACANCY}>
            <MenuItem>{t('vacancies')}</MenuItem>
          </Link>
          <Link to={PATHS.CONTACTS}>
            <MenuItem>{t('contacts')}</MenuItem>
          </Link>
        </MenuList>

        <TextField
          sx={{
            backgroundColor: '#EDF0F3',
            margin: 'auto',
            marginRight: 0,
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
          }}
          size="small"
          placeholder={t('search')}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img alt="search" src={search}></img>
              </InputAdornment>
            ),
          }}
          onClick={() => setSearchDisplay(true)}
          onChange={e => handleDebounce(e)}
        ></TextField>
        {searchContent !== '' && searchDisplay === true ? (
          <Box
            sx={{
              width: '440px',
              minHeight: '90px',
              maxHeight: '380px',
              display: 'flex',

              border: '3px solid #EDF0F3',
              backgroundColor: '#fff',
              zIndex: '10',
              position: 'absolute',
              overflowY: 'scroll',
              borderRadius: '8px',

              top: '118px',
              right: '14%',

              flexDirection: 'column',
              '& li': {
                listStyleType: 'none',
                mt: 2,
                mb: 2,
              },
            }}
            ref={searchModalWindow}
            onClick={handleSearchClose}
          >
            {searchResults?.search?.map((search, i) => (
              <li key={i}>
                <Link
                  to={
                    search?.path
                      ? search?.path
                      : search?.slug
                      ? `${PATHS.SERVICES}${search?.slug}`
                      : '/'
                  }
                  state={{ search: { search } }}
                >
                  <Box sx={{ ml: 3 }}>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        color: '#161A1D',
                      }}
                    >
                      {langType == 'ru'
                        ? search?.title_ru
                          ? search?.title_ru?.length >= 30
                            ? `${search?.title_ru.slice(0, 25)}...`
                            : search?.title_ru
                          : search?.name
                        : search?.title_ky
                        ? search?.title_ky?.length >= 30
                          ? `${search?.title_ky.slice(0, 25)}...`
                          : search?.title_ky
                        : search?.name}
                    </Typography>
                    <Typography>
                      {langType == 'ru'
                        ? search?.category_ru
                        : search?.category_ky}
                    </Typography>
                  </Box>
                </Link>
              </li>
            ))}
            {searchResults?.search.length > 0 ? (
              <Box
                sx={{
                  margin: 'auto',
                  '& a': {
                    textDecoration: 'none',
                  },
                }}
              >
                <Link
                  to={PATHS.SEARCH_RESULTS}
                  state={{ search: { searchResults } }}
                >
                  <Button
                    onClick={() => {
                      handleClose()
                    }}
                    sx={{
                      width: '186px',
                      height: '44px',
                      margin: 'auto',
                      mt: 3.5,
                      mb: 4,
                    }}
                    variant="contained"
                  >
                    {t('allResult')}
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box sx={{ margin: 'auto' }}>
                <Typography
                  sx={{
                    fontSize: '18px',
                    color: '#161A1D',
                  }}
                >
                  Результатов не найдено
                </Typography>
                <Typography sx={{ fontSize: '14px', color: '#44576A' }}>
                  Проверьте введённый запрос и попробуйте ещё раз
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Container>
  )
}

export default NavbarDesktopUI
