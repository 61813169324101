import React, { useEffect } from 'react'
import DoctorCard from 'components/UI/Cards/Doctor'
import PopularServiceCard from 'components/UI/Cards/PopularService'
import ReviewCard from 'components/UI/Cards/Review/ReviewCard'
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ServiseCategoryCard from 'components/UI/Cards/ServiceCategory/ServiceCategoryCard'
import {
  getPopularServices,
  selectAllShortServices,
  selectServicesState,
} from 'slices/servicesSlice'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Pagination, Navigation, Autoplay } from 'swiper'
import 'swiper/css/pagination'
import YouTube from 'react-youtube'

import {
  getBanners,
  getReviews,
  getStatisitic,
  selectInfoState,
} from 'slices/infoSlice'
import { PATHS, REQUEST_STATUSES } from 'common/constants'
import { selectDoctors } from 'slices/doctorsSlice'
import Banner from 'components/Banner'
import { getNews, selectAllNews } from 'slices/newsSlice'
import NewsCard from './NewsCard'
import Loader from 'components/UI/Loader/LoaderUI'
import { useTheme } from '@emotion/react'
import StatisticsComponent from './StatisticsComponent'
import { Link } from 'react-router-dom'
import ScrollToTopComponent from 'components/ScrollToTop/ScrollToTopComponent'
import MainPageDesktop from './Desktop/MainPageDesktop'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
const isLoadingStatus = status =>
  status === REQUEST_STATUSES.REQUESTED ||
  status === REQUEST_STATUSES.NOT_REQUESTED

const MainPage = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const { t } = useTranslation()

  const { statisticStatus, bannersStatus, banners, reviewsStatus, reviews } =
    useSelector(selectInfoState)

  // const shortServicesStatus = useSelector(
  //   state => state.services.shortServicesStatus
  // )
  const allShortServices = useSelector(selectAllShortServices)
  const doctors = useSelector(selectDoctors)
  const doctorsStatus = useSelector(state => state.doctors.doctorsStatus)

  const news = useSelector(selectAllNews)
  const newsStatus = useSelector(state => state.news.status)
  const { popularServices, popularServicesStatus } =
    useSelector(selectServicesState)

  // useEffect requests
  useEffect(() => {
    if (statisticStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      dispatch(getStatisitic())
    }

    if (popularServicesStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      dispatch(getPopularServices())
    }

    if (bannersStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      dispatch(getBanners())
    }

    if (reviewsStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      dispatch(getReviews())
    }
    if (newsStatus === REQUEST_STATUSES.NOT_REQUESTED) {
      dispatch(getNews())
    }
  }, [
    dispatch,
    statisticStatus,
    popularServicesStatus,
    bannersStatus,
    reviewsStatus,
    newsStatus,
  ])
  const videoUrl = 'https://www.youtube.com/watch?v=Ng49bhHwB0k'
  let videoCode
  if (videoUrl) {
    videoCode = videoUrl.split('v=')[1].split('&')[0]
  }
  const checkElapsedTime = e => {
    const duration = e.target.getDuration()
    const currentTime = e.target.getCurrentTime()
    if (currentTime / duration > 0.95) {
    }
  }
  const opts = {
    width: '100%',
    height: '185px',
  }

  const isLoading =
    isLoadingStatus(reviewsStatus) ||
    isLoadingStatus(bannersStatus) ||
    isLoadingStatus(popularServicesStatus) ||
    isLoadingStatus(statisticStatus) ||
    // isLoadingStatus(shortServicesStatus) ||
    isLoadingStatus(doctorsStatus) ||
    isLoadingStatus(newsStatus)

  if (isDesktop) {
    return <MainPageDesktop />
  }
  if (isLoading) {
    return (
      <Box
        position="fixed"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        bottom="0"
        width="100vw"
        overflow="hidden"
        bgcolor="primary.main"
      >
        <Loader
          sx={{
            // position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      pb={4.5}
      sx={{
        '& .MuiTypography-h2': {
          mb: 3,
        },

        // swiper styles
        '& .swiper': {
          pb: 7,
        },
        '& .swiper .swiper-slide': {
          height: 'auto',
        },
        '& .swiper-pagination ': {
          bottom: 0,
        },
        '& .swiper-pagination-bullet': {
          opacity: 1,
          bgcolor: 'primary.border',
        },
        '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
          bgcolor: 'primary.blue',
        },
      }}
    >
      <ScrollToTopComponent />
      {/* Banners */}
      <Box
        component="section"
        height={`calc(100vh - ${theme.spacing(10)})`}
        mb={4}
        sx={{
          '& .swiper': {
            height: '100%',
          },
        }}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          loop={true}
        >
          {banners.map(banner => (
            <SwiperSlide key={banner.id}>
              <Banner banner={banner} sx={{ height: '100%' }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* Popular Services */}
      <Box component="section" mb={6}>
        <Container>
          <Typography variant="h2">{t('popular')}</Typography>
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            // navigation={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            loop={true}
            style={{
              padding: '5px',
              paddingBottom: '20px',
            }}
          >
            {popularServices.map(popService => (
              <SwiperSlide key={popService.id}>
                <PopularServiceCard
                  service={popService}
                  sx={{
                    height: '100%',
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>

      {/* Short Services */}
      <Box component="section" mb={6}>
        <Container>
          <Typography variant="h2">{t('ourServices')}</Typography>
          <Grid
            spacing={2}
            container
            sx={{
              mb: 3,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {allShortServices.slice(0, -1).map(service => (
              <Grid xs={12} lg={4} item key={service.id}>
                <ServiseCategoryCard
                  service={service}
                  sx={{
                    height: '100%',
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {allShortServices.slice(-1)?.map(ambulance => (
            <Box
              key={ambulance.id}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  width: '95%',
                  border: '1px solid #CED7DE',
                  borderRadius: '14px',
                  margin: '0 auto',
                }}
              >
                <Box
                  sx={{
                    width: '48px',
                    height: '40px',
                    marginLeft: '18px',
                    mt: 2.625,
                    mb: 3,

                    '& img': {
                      width: '100%',
                      height: '40px',
                    },
                  }}
                >
                  <img src={ambulance.icon} alt="img" />
                </Box>
                <Box
                  sx={{
                    marginLeft: '20px',
                    marginRight: '20px',
                    height: '200px',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 400,
                      mb: 1,
                      mt: 1.5,
                    }}
                  >
                    {ambulance?.title}
                  </Typography>
                  <Typography variant="body1" fontWeight="400">
                    {ambulance.short_description}
                  </Typography>
                  <Box mt={2}>
                    <Link
                      to={`/services/${ambulance?.slug}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        sx={{
                          width: '130px',
                          height: '24px',
                          color: '#014782',
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                        variant="button"
                      >
                        Подробнее <NavigateNextIcon />
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          {!isDesktop && (
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                sx={{
                  p: 1.25,
                  pl: 2.75,
                  pr: 2.75,
                }}
                href="tel:1366"
              >
                <Typography
                  sx={{
                    '&:first-letter': {
                      textTransform: 'uppercase',
                    },
                  }}
                  variant="button"
                >
                  {t('callAmbulance')}
                </Typography>
              </Button>
            </Box>
          )}
        </Container>
      </Box>

      {/* About */}
      <Box component="section">
        <Container>
          <YouTube
            videoId={videoCode}
            containerClassName="embed embed-youtube"
            onStateChange={e => checkElapsedTime(e)}
            opts={opts}
          />
          {/* NOTE: this grid is for mobile and desktop  */}
          <Grid container>
            <Grid xs={12} lg={6} item>
              {/* TODO: paste the video */}
            </Grid>
            <Grid xs={12} lg={6} item>
              <Typography
                variant="h2"
                sx={{
                  '&.MuiTypography-h2': {
                    mb: 1.75,
                  },
                }}
              >
                {t('aboutClinic')}
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                }}
              >
                {t('AboutUsp1')}
              </Typography>
            </Grid>
          </Grid>
          <Button
            sx={{
              mb: 4,
              p: 0,
            }}
            LinkComponent={Link}
            to={PATHS.ABOUT_US}
            disableTouchRipple
          >
            {t('readMore')}
          </Button>
        </Container>
      </Box>

      {/* Doctors */}
      <Box
        component="section"
        pt={2.5}
        pb={6}
        mb={6}
        sx={{
          backgroundColor: '#EDF0F3',

          '& .swiper-slide': {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          },
          '& .swiper-slide  .MuiBox-root': {
            bgcolor: 'card.main',
          },
        }}
      >
        <Container>
          <Box mb={1.5}>
            <Typography
              variant="h2"
              sx={{
                '&.MuiTypography-root': {
                  display: 'inline-block',
                  mb: 0,
                  mr: 1.5,
                },
              }}
            >
              {t('doctors')}
            </Typography>
            <Typography
              color="primary.blue"
              sx={{
                display: 'inline',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              component={Link}
              to={PATHS.DOCTORS}
            >
              — {t('allDoctors')}
            </Typography>
          </Box>
        </Container>
        <Swiper
          slidesPerView={1}
          spaceBetween={8}
          // centeredSlides={true}
          // navigation={true}

          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
        >
          {doctors.slice(0, 3).map(doctor => (
            <SwiperSlide key={doctor.id}>
              <DoctorCard
                doctor={doctor}
                sx={{
                  '&:hover img': {
                    transform: 'scale(1.3)',
                  },

                  '& img': {
                    transition: 'transform .5s',
                    height: '180px',
                    width: '223px',
                    borderRadius: '8px',
                    ml: 'auto',
                    mr: 'auto',
                  },
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {/* Reviews */}
      <Box component="section" mb={6}>
        <Container>
          <Typography variant="h2" sx={{ mb: 1.75 }}>
            {t('reviews')}
          </Typography>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            // navigation={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            loop={true}
          >
            {reviews.map((review, idx) => (
              <SwiperSlide key={idx}>
                <ReviewCard
                  review={review}
                  sx={{
                    height: '100%',
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>

      {/* Statistics */}
      <StatisticsComponent />

      {/* News */}
      <Box component="section">
        <Container>
          <Typography variant="h2">{t('news')}</Typography>
          {news?.slice(0, 3)?.map(news => (
            <NewsCard news={news} sx={{ mb: 1.75 }} key={news.id} />
          ))}
        </Container>
      </Box>
    </Box>
  )
}

export default MainPage
