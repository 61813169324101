import React from 'react'
import { Box } from '@mui/system'
import { Container, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import LinksUI from 'components/UI/LinksUI'
import { t } from 'i18next'
const ContactsDesktop = () => {
  const { contacts } = useSelector(state => state.info)
  return (
    <Box sx={{ mb: 22.5 }}>
      <Container>
        <LinksUI path={t('contacts')} />
        <Typography sx={{ mb: 1.25 }} variant="h2">
          {t('contacts')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap',
            mt: 5,
          }}
        >
          {contacts &&
            contacts?.map(contact => (
              <Box
                sx={{
                  width: '48.5%',
                  height: '185px',
                  background: '#FFFFFF',
                  border: '1px solid #CED7DE',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  mt: 3.75,
                }}
                key={contact?.id}
              >
                <Box
                  sx={{
                    width: '50%',
                    height: '140px',
                  }}
                >
                  <Typography variant="subtitle2">{contact?.title}</Typography>

                  <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">
                    {contact?.description}
                  </Typography>
                </Box>
                <Box>
                  <img src={contact?.icon} width="96px" height="96px" alt="photo" />
                </Box>
              </Box>
            ))}
        </Box>
      </Container>
    </Box>
  )
}

export default ContactsDesktop
