import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FCFCFD',
      blue: '#014782',
      light: '#0270CA',
      border: '#CED7DE',
    },
    text: {
      main: '#44576A',
      black: '#161A1D',
    },
    card: {
      main: '#fff',
      text: '#2C3944',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      // check mobile from 360px to 414px
      // desktop begins from:
      lg: 1200,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        font-family: 'Inter', sans-serif
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 8,
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#014782',
              color: '#fff',

              '&:hover': {
                backgroundColor: '#0270CA',
              },
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: '#014782',

              '&:hover': {
                color: '#0286F2',
              },
            }),
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1110px !important',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: () => ({
          borderRadius: 8,
          border: '1px solid #CED7DE',
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          pl: 3,
          listStyleType: 'disc',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          pl: 0,
          pt: 1.2,
          mt: 0,
          '&::marker': {
            color: 'primary.blue',
          },
        },
      },
    },
  },
})

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: 28,
  lineHeight: '40px',
  fontWeight: 700,
  color: '#161A1D',

  [theme.breakpoints.up('lg')]: {
    fontSize: 48,
    lineHeight: '72px',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '36px',
  color: '#161A1D',

  [theme.breakpoints.up('lgf')]: {
    fontSize: 36,
    lineHeight: '48px',
  },
}

theme.typography.h3 = {
  ...theme.typography.h3,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '24px',
  color: '#161A1D',

  [theme.breakpoints.up('lg')]: {
    fontSize: 28,
    lineHeight: '40px',
  },
}

theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: 24,
  lineHeight: '36px',
}

// NOTE:  Popular service card's title from main page
theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  fontSize: 18,
  lineHeight: '24px',
  color: '#161A1D',

  [theme.breakpoints.up('lg')]: {
    fontSize: 22,
    lineHeight: '32px',
  },
}

// NOTE: Doctor card's title
theme.typography.subtitle3 = {
  ...theme.typography.subtitle3,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '24px',
  color: '#2C3944',
}

theme.typography.button = {
  ...theme.typography.button,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '24px',
  textTransform: 'none',
  color: '#FCFCFD',
}

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: 16,
  lineHeight: '22px',
  color: '#44576A',
}

theme.typography.caption = {
  ...theme.typography.caption,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '18px',
}

theme.typography.metadata = {
  ...theme.typography.metadata,
  fontSize: 12,
  lineHeight: '130%',
}

// NOTE: Header call number
theme.typography.call = {
  ...theme.typography.call,
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: '22px',
}
export default theme
