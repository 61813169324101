import * as React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function LinksUI({ path, path2 }) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        mt: 2.5,
        mb: 2,
        '& p': { fontSize: '14px' },
      }}
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Typography>{t('mainPage')} /</Typography>
      </Link>

      <Typography sx={{ fontWeight: 'bold', ml: 0.5 }}>{path} /</Typography>
    </Box>
  )
}
