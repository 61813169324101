import React from 'react'
import { Box, Container, Typography } from '@mui/material'

import { Link } from 'react-router-dom'

import call from '../../../assets/ui-icons/call.svg'
import logoMobile from '../../../assets/ui-icons/logoMobile.svg'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'

const HeaderDesktopUI = ({ changeLang }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        backgroundColor: '#EDF0F3',
        '& a': {
          textDecoration: 'none',
        },
      }}
    >
      <Container>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '64px',
            }}
          >
            <Link to="/">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& img': {
                    width: '38px',
                    mr: 1,
                  },
                }}
              >
                <img alt="logo" src={logoMobile}></img>
                <Typography
                  sx={{ fontSize: '18px', fontWeight: 500 }}
                  color="primary.blue"
                  variant="call"
                >
                  Клиника «МЕDИ»
                </Typography>
              </Box>
            </Link>

            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  '& img': {
                    width: '18px',
                  },

                  mr: 1,
                }}
              >
                <img alt="call" src={call} />
              </Box>

              <Typography
                sx={{ mr: 1, fontSize: '18px' }}
                color="primary.blue"
                variant="call"
              >
                {t('ambulance')}
              </Typography>
              <Typography
                sx={{ mr: 1, fontSize: '18px' }}
                color="primary.blue"
                variant="call"
              >
                1366
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{ fontSize: '18px', cursor: 'pointer' }}
                color={i18n.language === 'ru' ? 'primary.black' : '#8195A7'}
                onClick={() => changeLang('ru')}
              >
                Ру
              </Typography>
              /
              <Typography
                sx={{ fontSize: '18px', cursor: 'pointer' }}
                color={i18n.language === 'ky' ? 'primary.black' : '#8195A7'}
                onClick={() => changeLang('ky')}
              >
                Кырг
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default HeaderDesktopUI
