import { Box, Button, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import LinksUI from 'components/UI/LinksUI'
import { Container } from '@mui/system'
import { useSelector } from 'react-redux'
import NewsCardDesktop from './NewsCardDesktop'
import { REQUEST_STATUSES } from 'common/constants'
import SkeletonNews from 'components/UI/SkeletonNews'
import { useDispatch } from 'react-redux'
import { getNews } from 'slices/newsSlice'
import {useTranslation} from "react-i18next";

const NewsDesktop = props => {
  const {t} = useTranslation()

  const { newsId } = props

  const dispatch = useDispatch()

  const [pageNum, setPageNum] = useState(2)

  const pagination = `?page=${pageNum}`

  const { news, next, status } = useSelector(state => state?.news)
  const handleChangePage = useCallback(() => {
    setPageNum(prev => prev + 1)
    dispatch(getNews(pagination))
  }, [dispatch, pagination])

  return (
    <Box>
      <Container>
        <LinksUI path={t("news")} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">{t("news")}</Typography>
        </Box>
        {news &&
          news?.map(news => (
            <NewsCardDesktop key={news?.id} news={news} id={newsId} />
          ))}

        {status === REQUEST_STATUSES.REQUESTED && <SkeletonNews />}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {next ? (
            <Button
              variant="contained"
              sx={{
                color: 'white',
                width: '323px',
                height: '44px',
                mb: 11.25,
              }}
              onClick={handleChangePage}
            >
              Загрузить еще
            </Button>
          ) : null}
        </Box>
      </Container>
    </Box>
  )
}

export default NewsDesktop
