import React, { memo, useState, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Container } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { getNews } from 'slices/newsSlice'
import { useDispatch, useSelector } from 'react-redux'
import NewsCard from './NewsCard'
import theme from 'theme'
import LinksUI from 'components/UI/LinksUI'
import NewsDesktop from './Desktop/NewsDesktop'
import SkeletonNews from 'components/UI/SkeletonNews'
import { REQUEST_STATUSES } from 'common/constants'
import { useLocation } from 'react-router-dom'

import { reset } from 'slices/newsSlice'
import {useTranslation} from "react-i18next";

const NewsPage = memo(() => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  useEffect(() => {}, [location])

  const newsId = `?id=${location?.state?.search?.search?.id}`

  const [pageNum, setPageNum] = useState(2)

  const pagination = `?page=${pageNum}`

  useEffect(() => {
    dispatch(reset())
    if (location?.state?.search?.search?.id) {
      dispatch(getNews(newsId))
    } else {
      dispatch(getNews())
    }
  }, [dispatch, location?.state?.search?.search?.id, newsId, pageNum])
  const handleChangePage = async () => {
    setPageNum(prev => prev + 1)
    dispatch(getNews(pagination))
  }

  const { news, next, status } = useSelector(state => state?.news)

  const desktop = useMediaQuery(theme.breakpoints.up('lg'))
  if (desktop) {
    return <NewsDesktop newsId={newsId} />
  }
  return (
    <Box>
      <Container>
        <LinksUI path={t("news")} />
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ mt: 2 }} variant="h2">
            {t("news")}
          </Typography>
        </Box>
        {news?.map((item, idx) => (
          <NewsCard key={item?.id} item={item} id={newsId} />
        ))}
        {status === REQUEST_STATUSES.REQUESTED && <SkeletonNews />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 8,
          }}
        >
          {next ? (
            <Button
              variant="contained"
              sx={{
                color: 'white',
                width: '323px',
                height: '44px',
                mb: 11.25,
              }}
              onClick={handleChangePage}
            >
              {t('loadMore')}
            </Button>
          ) : null}
        </Box>
      </Container>
    </Box>
  )
})

export default NewsPage
